
.CheckLanguageOutOut{
    position: relative;
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
    top: -1px;
    left: 60%;
    z-index: 100;
}


.CheckLanguage{
    display: flex;
}

.CheckLanguageImg{
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 5px;
}

.CheckLanguageImg>img {
    border-radius: 9px;
}

.CheckLanguageTitle{
    font-weight: 500;
    font-size: 13px;
    color: #1466b4;
    text-decoration: underline;
}
.CheckLanguageTitle:hover{
    color: #20af1b;
}

@media only screen and (max-width: 900px) {
.MainMenuGlobalFirstPart{
    left: -20%;
}
    .CheckLanguageOutOut{
        left: 45%;
    }
}

@media only screen and (max-width: 650px) {
    .CheckLanguageOutOut{
        left: 40%;
    }
}

.CheckLanguageOut>a{
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 800px) {
    .CheckLanguageOutOut>.CheckLanguageOut>a>.CheckLanguage>.CheckLanguageTitle{
        display: block;
    }
}

@media only screen and (max-width: 500px) {
        .CheckLanguageOutOut{
            position: relative;
            left: -10px;
            top: -5px;
    }
    .MainMenuGlobalFirstPart{
        left: 0;
    }
}
@media only screen and (max-width: 410px) {
    .MainMenuGlobalFirstPartOut{

    }
        .CheckLanguageOutOut{
            position: static;
            top: 100px;
            left: -1px;
    }
}

.CheckLanguageOut{
    width: 92px;
    height: 123px;
    background-color: white;
    box-shadow: 0 4px 10px 0 rgba(0, 51, 100, 0.25);
    border-radius: 3px;
    position: absolute;
    z-index: 150;
    padding: 14px 14px 10px 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: 1px;
    left: 2px;
}


.CheckLanguageNo{
    display: none;
}

.CheckLanguage{
    display: flex;
    align-items: center;
}

.CheckLanguageImg{
    padding-right: 10px;
}

.CheckLanguageOutLinkActive{
    color: black;
    text-decoration: none;
}