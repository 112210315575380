.WidgetEveryPageText01{
    font-size: 24px;
    font-weight: 500;
    color: #151e27;
    padding-bottom: 30px;
}

.WidgetEveryPageText02{
    font-size: 18px;
    font-weight: 400;
    color: #151e27;
}

.WidgetEveryWarning01{
    display: flex;
}

.WidgetEveryWarning01>img{
    padding-right: 15px;
}

.PersonalPageInputBottomLangCode{
    margin-top: 10px;
    margin-bottom: 10px;
}

.WidgetEveryWarning01>div>b{
    margin-right: 5px;
}

.WidgetEveryWarning01>div>i{
    margin-left: 5px;
    margin-right: 5px;
}
