
@media only screen and (max-width: 1200px) {
    .TextWidgetEducation{
        font-size: 10px;
    }
    .PersonalPageInputBottomLangCode{
        font-size: 12px;
    }
}
@media only screen and (max-width: 800px) {
    .PersonalPageInput{
        width: auto;
    }
    .PersonalPageInputBottomLangCode{
        font-size: 14px;
    }
    .PageMainPartBig{
        font-size: 14px;
    }
}

@media only screen and (max-width: 660px) {
    .PageAdvantageBottomPassword>img{
        width: calc(100% - 10px);
    }
    .PersonalPageInputBottomLangCode{
        font-size: 10px;
    }
}
@media only screen and (max-width: 550px) {
    .PageMainPartBig{
        font-size: 10px;
    }
}