.FooterAllOut{
    margin-top: 30px;
    border-top: 1px solid #147dcd;
    padding-top: 29px;
}

.FooterAll{
    padding: 10px 60px 67px 15px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 20px;
}

.FooterAllTitleMenu>a{
    text-decoration: none;
}

.MainMenuFooter{
    display: flex;
    flex-direction: column;
}

.MainMenuFooter>div{
    padding-top: 5px;
}

.MainMenuFooter>div>a{
    color: #151e27;
    font-size: 24px;
}

.MobiusHeaderLogoText{
    text-decoration: none;
}

.TopWidgetsConnect{
    display: grid;
    grid-template-columns: 40px auto;
    grid-column-gap: 10px;
}

.FooterLeftMenuElement{
    display: flex;
    padding-top: 10px;
}

.FooterLeftMenuElementNumber{
    font-size: 18px;
    color: #151e27;
    font-weight: 700;
    text-decoration: none;
    width: 10px;
}

.TopWidgetsConnectRight>a,.TopWidgetsMaterialsTitle>a{
    text-decoration: none;
}

.FooterLeftMenuElementText{
    text-decoration: underline;
    padding-left: 5px;
    color: #1466b6;
}

.TopWidgetsMaterials{
    display: grid;
    grid-template-columns: 40px auto;
    grid-column-gap: 10px;
}

.TopWidgetsMaterialsGrid{
    padding-top: 10px;
    font-size: 20px;
    color: #151e27;
    font-weight: 700;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
}

.TopWidgetsMaterialsGridElementFlexElement{
    font-size: 18px;
    font-weight: 400;
    padding-top: 5px;
}

.TopWidgetsMaterialsGridElementFlexElement>a{
    color: #1466b6;
}

.TopWidgetsMaterialsGridElementTitle>a{
    text-decoration: none;
}
.TopWidgetsConnectIMG>img{
    position: relative;
    top: -10px;
}
.TopWidgetsMaterialsIMG>img{
    position: relative;
    top: -20px;
}

@media only screen and (max-width: 1500px) {
   .FooterAll{
        padding: 10px 20px 67px 15px;   }

}
@media only screen and (max-width: 1200px) {
   .FooterAll{
       grid-template-columns: auto auto auto;
   }
    .FooterAllTitleMenu{
        grid-column: 1/span 3;
        display: flex;
        padding-bottom: 30px;
    }
    .MainMenuFooter{
        flex-direction: row;
    }
    .MainMenuFooter>div{
        padding-left: 40px;
    }
    .TopWidgetsMaterialsGrid{
        display: block;
    }
    .TopWidgetsMaterialsGridElement{
        padding-top: 10px;
    }
}

@media only screen and (max-width: 900px) {
    .FooterAll{
        grid-template-columns: auto auto;
    }
    .FooterAllTitleMenu{
        grid-column: 1/span 2;
    }

    .MainMenuFooter{
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 800px) {
    .FooterLeftMenuElement{
        margin-top: 20px;
    }
    .FooterLeftMenuElementNumber{
        font-size: 26px;
    }
    .FooterLeftMenuElementText{
        font-size: 22px;
        padding-left: 15px;
        line-height: 25px;
    }

    .TopWidgetsMaterialsGridElementFlexElement{
        font-size: 26px;
        font-weight: 400;
        padding-top: 15px;
    }

    .TopWidgetsMaterialsGridElementTitle{
        font-size: 36px;
        padding-top: 15px;
    }
    .TopWidgetsThreeElement{
        margin-top: 20px;
    }

    .MainMenuFooter>div{
        padding: 20px;
    }
    .TopWidgetsConnect{
        margin-bottom: 20px;
    }
    .TopWidgetsConnectRight>a:first-child{
        font-size: 25px;
    }
}

@media only screen and (max-width: 600px) {
    .FooterAll{
        display: block;
    }

}