
.ActivationOwnServerH1{
    font-family: Roboto, sans-serif;
    padding-bottom: 35px;
    font-size: 27px;
}

.ActivationOwnServerH2{
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 18px;
}

.ActivationOwnServerGrid{
    display: grid;
    grid-template-columns: 240px auto;
    grid-column-gap: 25px;
    margin-top: 40px;
}

.ActivationOwnServerGridRightTextBorder{
    width: 1px;
    border: 2px solid #8076c3;
    border-radius: 2px;
    margin-right: 10px;
}

.ActivationOwnServerGridRight{
    display: flex;
}

.ActivationOwnServerGridRightRight>.ActivationOwnServer01{
    margin-left: 26px;
}

.ActivationOwnServerGridRightText{
    display: flex;
    padding-left: 14px;
}

.ActivationOwnServerAWS{
    border: solid 2px #ff9900;
    border-radius: 5px;
    padding: 30px 60px 30px 37px;
    display: grid;
    grid-template-columns: 84px auto;
    grid-column-gap: 50px;
    margin-top: 36px;
    margin-bottom: 40px;
    align-items: center;
}

.ActivationOwnServerH2>b{
    padding-right: 5px;
}
.ActivationOwnServerAWSText>a{
    margin-left: 5px;
}
.ActivationOwnServerAWSText>span{
    margin-right: 5px;
}

@media only screen and (max-width: 700px) {
    .ActivationOwnServerGrid{
        grid-template-columns: auto;
    }
    .ActivationOwnServerGrid>img{
        margin: auto;
        max-width: 200px;
        align-self: center;
    }

}
