.FAQTitle>div>h1>.TitleGlobal{
    font-size: 50px;
}

@media only screen and (max-width: 600px) {
    .TitleGlobal{
        font-size: 32px;
    }
    .FAQTitleTop{
        display: flex;
        flex-wrap: wrap;
    }
    .FAQTitleTop>.TitleGlobal{
        padding-bottom: 15px;
    }
    .FAQTitleTop>.TradingFAQButtonButtonAll{
        font-size: 18px;
    }

}

@media only screen and (max-width: 500px) {
    .TradingFAQButtonButtonAll > .TradingFAQButtonButtonAllCenter {
        display: none;
    }

    .FAQTitleTop > .TradingFAQButtonButtonAll {
        font-size: 18px;
        display: grid;
        grid-template-columns: auto;
    }

    .FAQTitle >.FAQTitleTop {
        display: block;
    }
}


.TradingFAQButtonButtonAllDivActive{
    cursor: pointer;
}

.FAQTitleTop{
    display: flex;
    justify-content: space-between;
}

.TradingFAQButtonButtonAll{
    font-weight: 400;
    font-size: 24px;
    color: #1466b6;
}

.TradingFAQButtonButtonAll>div>img{
    padding-right: 15px;
}

.TradingFAQButtonButtonAll>div{
    padding: 14px 15px 16px 13px;
    height: 22px;
    display: flex;
    align-items: center;
}

.TradingFAQButtonButtonAllDivActiveNo{
    color: gray;
    cursor: default;
}
.TradingFAQButtonButtonAllDivActiveNo>.FAQIMGGray{
    display: none;
}
.TradingFAQButtonButtonAllDivActive>.FAQIMG{
    display: none;
}

.TradingFAQButtonButtonAll{
    display: flex;
}


.TradingFAQPartElement{
    background-color: white;
    padding: 24px 58px 0 19px;
    margin-bottom: 10px;
    cursor: pointer;
}


.TradingFAQPartElement>.TradingFAQPartElementText{
    display: none;
}



.TradingFAQPartElementActive>.TradingFAQPartElementTitle{

}

.TradingFAQPartElementActive>.TradingFAQPartElementText{
    display: block;
    margin-top: 10px;
    font-weight: 500;
    font-size: 24px;
    color: rgb(24,24,24);
}

.TradingFAQPartTitle{
    padding-top: 20px;
    font-size: 36px;
}

.TradingFAQPartElementTitle{
    font-weight: 400;
    font-size: 26px;
    color: #1466b6;
    text-decoration: underline;
}