.WidgetLeftMenuTitle{
    font-size: 36px;
    font-weight: bold;
    text-align: left;
    color: black;
    padding-bottom: 42px;
}

.WidgetLeftMenu{
    display: flex;
    flex-direction: column;
}

.WidgetLeftMenu>a{
    font-size: 24px;
    text-align: left;
    color: #1466b6;
}
.WidgetLeftMenu>a{
    padding-bottom: 20px;
}

.WidgetLeftMenu>.WidgetLeftMenuLinkActive{
    font-size: 24px;
    font-weight: bold;
    color: black;
    cursor: unset;
    text-decoration: none;
}

@media only screen and (max-width: 500px) {
    .WidgetLeftMenu>a{
       font-size: 17px;
    }
    .WidgetLeftMenu>a>div{
        padding-bottom: 10px;
    }
    .StandartTextWidget>.PageMainPartMainSVG{
        margin-top: 30px;
    }
}
