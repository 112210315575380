.LeftMenu{
    display: flex;
    flex-direction: column;
    width: auto;
    padding-top: 15px;
}

.LeftMenu>.WidgetLeftMenuLinkActive{
    font-size: 24px;
    font-weight: bold;
    color: black;
    cursor: unset;
    text-decoration: none;
}
.LeftMenu>a{
    text-decoration: none;
}

.LeftMenuElement{
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    margin-right: 28px;
}
.WidgetLeftMenuLinkActive>.LeftMenuElement>.LeftMenuElementNumber{
    color: white;
    background-color: #1466b4;
}
.LeftMenuElement>.LeftMenuElementNumber{
    border-radius: 24px;
    margin-right: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    width: 28px;
    height: 28px;
    color: #025d92;
}

.LeftMenuElementActiveNot>.LeftMenuElementText,.hrefChangeNavLink>div>.LeftMenuElementText{
    font-weight: 500;
    font-size: 16px;
    color: #0079bf;
    width: 270px;
    text-decoration: underline;
}

.LeftMenu>.hrefChangeNavLink>div{
    display: flex;
    align-items: center;
}
.LeftMenu>.hrefChangeNavLink>div>.LeftMenuElement{
    background-color: rgba(239, 244, 249, 0.4);
    border-radius: 24px;
    margin-right: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    width: 28px;
    height: 28px;
    color: #0079bf;
}

.LeftMenuElementActiveNot>.LeftMenuElementNumber{
    text-decoration: none;
}

.WidgetLeftMenuLinkActive>div>.LeftMenuElementText,.WidgetLeftMenuLinkActive>div>div>.LeftMenuElementText{
    font-weight: 200;
    font-size: 16px;
    color: black;
    text-decoration: none;
    width: 270px;
}

.LeftMenuElement>.LeftMenuElementNumber{
    background-color: #eff4f9;
}


@keyframes LeftMenuElement {
    from {
        left: -800px;
    }

    to {
        left: 0;
    }
}

.LeftMenu>a:nth-of-type(1){
    animation-name: LeftMenuElement;
    animation-duration: 500ms;
    animation-delay: 10ms;
    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}
.LeftMenu>a:nth-of-type(2){
    animation-name: LeftMenuElement;
    animation-duration: 500ms;
    animation-delay: 200ms;
    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}
.LeftMenu>a:nth-of-type(3){
    animation-name: LeftMenuElement;
    animation-duration: 500ms;
    animation-delay: 300ms;
    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}
.LeftMenu>a:nth-of-type(4){
    animation-name: LeftMenuElement;
    animation-duration: 400ms;
    animation-delay: 400ms;
    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}
.LeftMenu>a:nth-of-type(5){
    animation-name: LeftMenuElement;
    animation-duration: 500ms;
    animation-delay: 500ms;
    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}
.LeftMenu>a:nth-of-type(6){
    animation-name: LeftMenuElement;
    animation-duration: 500ms;
    animation-delay: 600ms;
    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}
.LeftMenu>a:nth-of-type(7){
    animation-name: LeftMenuElement;
    animation-duration: 500ms;
    animation-delay: 700ms;
    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}
.LeftMenu>a:nth-of-type(8){
    animation-name: LeftMenuElement;
    animation-duration: 500ms;
    animation-delay: 800ms;
    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}
.LeftMenu>a:nth-of-type(9){
    animation-name: LeftMenuElement;
    animation-duration: 500ms;
    animation-delay: 900ms;
    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}

.LeftMenu>a:nth-of-type(10){
    animation-name: LeftMenuElement;
    animation-duration: 500ms;
    animation-delay: 1000ms;
    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}


.PageActivationIndex>.PageMainPartBig{
    width: 877px;
}

.PageMainPartBig{
    margin-top: -16px;
    max-width: 885px;
}

.StandartTextActivation{

    font-weight: 400;
    font-size: 18px;
    color: black;
    line-height: 27px;
}

.StandartTitleActivation{
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 52px;
    color: black;
    line-height: normal;
}

.ActivationGeneralOptionsStandartElement{
    display: grid;
    grid-template-columns: 14px auto;
    grid-column-gap: 16px;
}

.ActivationGeneralOptionsSquareOut{
    width: 14px;
    height: 14px;
    position: relative;
    background-color: #cce4f2;
    margin-top: 1px;
}


.ActivationGeneralOptionsSquareIn{
    width: 8px;
    height: 8px;
    position: relative;
    top: 3px;
    left: 3px;
    background-color: white;
}

.ActivationGeneralOptionsStandartElementRightTitle{
    font-weight: 500;
    font-size: 18px;
    color: black;
    line-height: normal;
}

.ActivationStandertPadBot{
    padding-bottom: 10px;
}

.StandartTitleActivationH3{
    font-weight: 500;
    font-size: 18px;
    color: #020202;
}

.ActivationMobiusIndexTextBottom>.IndexWidgetButtonOut01{
    margin-top: 60px;
}
