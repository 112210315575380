.ActivationWeGive>.ActivationWeGive01{
    padding-top: 30px;
    padding-bottom: 20px;
    font-weight: 500;
}

.ActivationWeGive02{
    padding-top: 15px;
    padding-bottom: 30px;
}

.ActivationGeneralOptionsViewNotActive>div{
    display: grid;
}

.ActivationWeGiveH2{
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 18px;
}

.MobiusTrader7TopTerminals{
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-around;
    align-items: center;
}

.MobiusTrader7TopTerminals>div{
    background-repeat: no-repeat;
    cursor: pointer;
}

.MobiusTrader7TopTerminalsElement03{
    background-image: url("../../../static/img/Advantages/Android.svg");
    width: 19px;
    height: 23px;
}
.MobiusTrader7TopTerminalsElement03:hover{
    background-image: url("../../../static/img/Advantages/Android_active.svg");
    color: #305d92;
}
.MobiusTrader7TopTerminalsElement01{
    background-image: url("../../../static/img/Advantages/Windows.svg");
    width: 20px;
    height: 20px;
}
.MobiusTrader7TopTerminalsElement01:hover{
    background-image: url("../../../static/img/Advantages/Windows_active.svg");
    color: #305d92;
}
.MobiusTrader7TopTerminalsElement02{
    background-image: url("../../../static/img/Advantages/Apple.svg");
    width: 19px;
    height: 24px;
}
.MobiusTrader7TopTerminalsElement02:hover{
    background-image: url("../../../static/img/Advantages/Apple_active.svg");
    color: #305d92;
}
.MobiusTrader7TopTerminalsElement04{
    background-image: url("../../../static/img/Advantages/Linux.svg");
    width: 18px;
    height: 21px;
}
.MobiusTrader7TopTerminalsElement04:hover{
    background-image: url("../../../static/img/Advantages/Linux__active.svg");
    color: #305d92;
}
.MobiusTrader7TopTerminalsElement05{
    background-image: url("../../../static/img/Advantages/MacOS.svg");
    width: 20px;
    height: 20px;
}
.MobiusTrader7TopTerminalsElement05:hover{
    background-image: url("../../../static/img/Advantages/MacOS_active.svg");
    color: #305d92;
}
.MobiusTrader7TopTerminalsElement06{
    background-image: url("../../../static/img/Advantages/WebTerminal.svg");
    width: 20px;
    height: 20px;
}
.MobiusTrader7TopTerminalsElement06:hover{
    background-image: url("../../../static/img/Advantages/WebTerminal_active.svg");
    color: #305d92;
}

.WeGiveViewNotText{
    margin-top: 14px;
    padding-top: 14px;
}
.WeGiveViewNotAllInfo{
    display: grid;
    grid-template-columns: auto 130px;
    grid-column-gap: 25px;
    /*padding: 14px;*/
}
.WeGiveViewNotText{
    grid-column: 1;
}
.WeGiveViewNotTextInfo{
    grid-column: 2;
    align-self: center;
}

.WeGiveViewNotActive>.WeGiveViewNotAllInfo{
    display: grid;
}

.WeGiveViewNotTitle{
    font-weight: 500;
    font-size: 18px;
    color: #0247be;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 15px;
    display: flex;
    cursor: pointer;
}
.WeGiveViewNotTitle>span{
    border-bottom: 2px dashed #0247be;
    display: flex;
}

.WeGiveStandartElementForPadding{
    padding-top: 35px;
    padding-bottom: 35px;
}

.WeGiveViewNotAllInfo{
    position: relative;
}

.WeGiveViewNotAllInfo:before{
    content: '';
    height: 100%;
    width: 15px;
    background-color: rgba(116,164,247,0.5);
    position: absolute;
    left: -15px;
}

.WeGiveViewNotTextElements{
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;
    font-weight: 500;
    font-size: 18px;
    color: black;
    line-height: 24px;
    margin-bottom: 15px;
}

.WeGiveViewNotTextElement{
    width: 100px;
    text-align: center;
}

.WeGiveViewNotAllInfo{
    background-color: rgba(116,164,247,0.5);
    padding: 0 22px 16px 0;
    display: none;
}

.ActivationWeGiveH1{
    font-family: Roboto, sans-serif;
    padding-bottom: 35px;
    font-size: 27px;
}


.ActivationWeStandartDistributive{
    display: flex;
}


.ActivationWeStandartDistributive>a{
    padding-right: 24px;
}

.ActivationWeGive03{
    padding-top: 17px;
    padding-bottom: 25px;
}

.ActivationWeGive04{
    padding-top: 40px;
    padding-bottom: 20px;
}

.WeGiveGridTerminal{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 20px;
}

.WeGiveGridTerminalRight{
    width: 298px;
    height: 148px;
}

.WeGiveGridTerminalLeftLi{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 70%;
}

.WeGiveGridTerminalLeftLiElement{
    display: flex;
    padding-left: 10px;
    padding-bottom: 5px;
    margin-top: 6px;
}

.WeGiveGridTerminalLeftTitle{
    font-weight: 700;
    font-size: 18px;
    color: #020202;
    padding-bottom: 15px;
}

.WeGiveGridTerminalLeftLi>li{
    list-style: none;
    display: flex;
}

.WeGiveGridTerminalLeftLi>li:before{
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #0056ec;
    margin-right: 10px;
    margin-top: -2px;
    display: block;
    float: left;
    position: relative;
    top: 3px;
}

.ActivationWeGive05{
    padding-top: 25px;
    padding-bottom: 20px;
}

.WeGiveGridAdmin{
    display: grid;
    grid-template-columns: 250px auto;
    grid-column-gap: 50px;
    padding-top: 40px;
}

.WeGiveGridAdminLeft{
    width: 250px;
    height: 165px;
}

.WeGiveGridAdminRightLi{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 70%;
}

.WeGiveGridAdminRightTitle{
    font-weight: 700;
    font-size: 18px;
    color: #020202;
}

.WeGiveGridAdminRightLiElementRound{
    width: 10px;
    height: 10px;
    background-color: #0056ec;
    border-radius: 50%;
    position: relative;
    top: 2px;
}

.WeGiveGridAdminRightLi{
    padding-top: 15px;
}

.WeGiveGridAdminRightLi>.WeGiveViewNotAllInfo{
    list-style: none;
    display: flex;
    padding-bottom: 10px;
}

.ActivationGeneralOptionsViewNotActive>div>span{
    border: none;
}

.WeGiveGridAdminRightLiElementText{
    width: 250px;
    padding-left: 10px;
}


.WeGiveGridWidgets{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 20px;
}

.WeGiveGridWidgetsRight{
    width: 170px;
    height: 219px;
}

.WeGiveGridWidgetsLeftLi{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 70%;
}

.WeGiveGridWidgetsLeftTitle{
    font-weight: 700;
    font-size: 18px;
    color: #020202;
}

.WeGiveGridWidgetsLeftLi>li{
    list-style: none;
    display: flex;
}

.WeGiveGridWidgetsLeftLi>li>a{
    color: #0247be;
}


.WeGiveGridWidgetsLeftLi>li:before{
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #0056ec;
    margin-right: 10px;
    margin-top: -2px;
    display: block;
    float: left;
    position: relative;
    top: 3px;
}

.ActivationWeGive07{
    padding-top: 40px;
    padding-bottom: 10px;
}

.WeGiveGridAdminRightLiElement{
    padding-left: 10px;
    margin-top: 6px;
}

.WeGiveGridAdminRightLiElementText>a{
    color: #0247be;
    text-decoration: none;
    border-bottom: 1px solid #CCCCCC;
}

.WeGiveGridWidgetsLeftTitle{
    padding-bottom: 15px;
}

.WeGiveGridWidgetsLeft>div{
    padding-bottom: 10px;
}


@media only screen and (max-width: 800px) {
    .WeGiveGridTerminal{
        grid-template-columns: auto;
    }
    .WeGiveGridAdmin{
        grid-template-columns: auto;
    }
    .WeGiveGridAdminLeft{
        padding-bottom: 20px;
        margin: auto;
    }
    .WeGiveGridWidgets{
        grid-template-columns: auto;
    }
    .WeGiveGridWidgetsRight{
        grid-row: 1;

        padding-bottom: 20px;
        margin: auto;
    }
    .WeGiveGridTerminalRight{
        grid-row: 1;
        padding-bottom: 20px;
        margin: auto;
    }
}

@media only screen and (max-width: 370px) {
    .WeGiveGridTerminalRight,.WeGiveGridTerminalRight>img{
        width: 100%;
    }

}

@media only screen and (max-width: 500px) {
    .StandartTitleActivation{
        font-weight: 500;
        font-size: 30px;
    }
    .ActivationWeGive>.ActivationWeGiveH1{
        font-size: 24px;
        font-weight: 400;
    }
    .ActivationWeGive>.ActivationWeGiveH2{
        font-size: 18px;
        font-weight: 400;
    }
    .ActivationWeGive>.WeGiveViewNotActive>.WeGiveViewNotAllInfo{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .WeGiveViewNotAllInfo:before{
        top: 0;
    }
    .WeGiveViewNotTextInfo{
        padding-top: 20px;
    }
    .WeGiveViewNotActive>.WeGiveViewNotAllInfo:before {
        width: 20px;
        left: -20px;
        height: 100%;
    }
    .WeGiveViewNotActive>.WeGiveViewNotAllInfo:after {
        content: "";
        position: absolute;
        top: 0;
        width: 20px;
        right: -20px;
        height: 100%;
        background-color:  rgba(116,164,247,0.5);
    }
    .ActivationWeStandartDistributive{
        flex-wrap: wrap;
        justify-content: center;
    }
}

.MobiusTrader7TopTerminals>div{
    height: 25px;
    font-size: 18px;
    font-weight: 700;
    color: #4a90e2;
    padding-left: 30px;
    display: flex;
    align-items: flex-end;
    width: auto;
    margin-right: 15px;
    margin-top: 10px;
}

.MobiusTrader7TopTerminalsElement01>div{
    position: relative;
    top: -2px;
    left: 2px;
}
.MobiusTrader7TopTerminalsElement02{
    position: relative;
    top: -2px;
    left: 2px;
}
.MobiusTrader7TopTerminalsElement03{
    position: relative;
    top: -1px;
    left: 2px;
}
.MobiusTrader7TopTerminalsElement03>div{
    position: relative;
    top: -1px;
    left: 2px;
}
.MobiusTrader7TopTerminalsElement04>div{
    position: relative;
    top: -2px;
    left: 2px;
}
.MobiusTrader7TopTerminalsElement05>div{
    position: relative;
    top: -2px;
    left: 2px;
}
.MobiusTrader7TopTerminalsElement06>div{
    position: relative;
    top: -2px;
    left: 5px;
}
