.TerminalTitle{
    font-weight: 700;
    font-size: 30px;
    color: #413422;
    padding-top: 8px;
}

.TerminalLinux{
    font-family: Geometria, sans-serif;
}
.TerminalLinuxIn{
    max-width: 1078px;
    margin: auto;
}

.TerminalLinuxPart01Top{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.TerminalLinuxPart01Top>img{
    padding-top: 10px;

}

.TerminalLinuxInPart01Right{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TerminalLinuxInPart01Right>.TerminalWebComponent3IMG>.TerminalWebComponent3IMGCircles{
    margin-bottom: 30px;
}

@media only screen and (max-width: 970px) {
    .TerminalLinuxInPart01Right>.TerminalWebComponent3IMG>.TerminalWebComponent3IMGImage>.TerminalWebComponent3IMGImageIn>img{
        width: 100%;
        object-fit: contain;
    }
}

@media only screen and (max-width: 700px) {
    .TerminalLinuxPart01Top{
        justify-content: center;
    }
    .TerminalWeb>.TerminalIn>.TerminalLinuxPart01{
        padding-right: 10px;
        padding-left: 10px;
    }
    .TerminalInPart01LeftText{
        padding-right:  20px;
    }
}

.TerminalLinuxInPartStore{
    padding-top: 0;
}

.TerminalLinuxInPart01RightMainImage{
    position: relative;
}


.TerminalLinuxInPartStore>.TerminalInPart01LeftStore,.TerminalLinuxInPartStore>.TerminalInPart01LeftText{
    padding-bottom: 10px;
}

@media only screen and (max-width: 400px) {
    .TerminalLinuxPart01Top>img{
        display: none;
    }
}

.TerminalLinuxPart01TextMain{
    padding-bottom: 20px;
    font-weight: 400;
    font-size: 18px;
    color: #413422;
    padding-top: 30px;
    line-height: 27px;
}

.TerminalLinuxPart02Out{
    background-color: #f2f2ee;
}
.TerminalLinuxPart02{
    display: grid;
    grid-template-columns: 600px auto;
    grid-column-gap: 40px;
    padding-top: 40px;

}

.TerminalLinuxPart02>.TerminalLinuxPart02Left>img{
    object-fit: contain;
}

.TerminalLinuxPart03{
    display: grid;
    grid-template-columns: auto 600px;
    grid-column-gap: 40px;
    padding-top: 40px;
}

.TerminalLinuxPart03>.TerminalLinuxInPart03Right>img{
    object-fit: contain;
}


.TerminalLinuxPart02Left,.TerminalLinuxInPart03Right{
    position: relative;
    overflow: hidden;
}

    .TerminalLinuxPart02{
        padding-left: 0;
        grid-template-columns: auto 300px;
        position: relative;
    }
    .TerminalLinuxPart02>.TerminalLinuxPart02Left>img{
        position: absolute;
        right: -3px;
    }
    .TerminalLinuxPart02:before{
        content: '';
        height: 100%;
        width: 500px;
        position: absolute;
        top: 0;
        left: -500px;
        z-index: 10;
        background-color: #edf1f1;
    }
    .TerminalLinuxPart03{
        padding-right: 0;
        padding-left: 0;
        grid-template-columns: 300px auto;
        position: relative;
    }
    .TerminalLinuxPart03>.TerminalLinuxInPart03Right{overflow: hidden;}


    .TerminalLinuxPart03>.TerminalLinuxInPart03Right>img{
        position: absolute;
        left: -3px;
        overflow: hidden;

    }


.TerminalLinuxInPart02Right>ul,.TerminalLinuxInPart03Left>ul{
    padding: 0;
}

.TerminalLinuxInPart02RightAndroidDesktop>.TerminalTitleH2{
    margin-top: 40px;
}


.TerminalLinuxInPart02Right>ul>li,.TerminalLinuxInPart03Left>ul>li{
    line-height: 27px;
    color: #413422;
    font-size: 16px;
    list-style: none;
    margin-top: 25px;
}

.TerminalLinuxInPart02Right>ul>li:before,.TerminalLinuxInPart03Left>ul>li:before{
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #ff9306;
    margin-right: 10px;
    margin-top: 5px;
    display: block;
    float: left;
    position: relative;
    top: 3px;
}

.TerminalLinuxInPart02Right>.TerminalTitleH2,.TerminalLinuxInPart03Left>.TerminalTitleH2{
    padding: 0 30px 0 0;
    font-size: 24px;
    line-height: 28px;
    color: #413422;
}

@media only screen and (max-width: 600px) {
    .TerminalLinuxInPart02Right>.TerminalTitleH2,.TerminalLinuxInPart03Left>.TerminalTitleH2 {
        padding-top: 20px;
    }
    .TerminalLinuxPart02{
        display: flex;
        flex-direction: column;
        background-color: white;
        padding-right: 0;
    }
    .TerminalLinuxInPart02Right,.TerminalLinuxInPart03Left{
        padding-left: 20px;
        padding-right: 20px;
    }
    .TerminalLinuxPart02>.TerminalLinuxPart02Left>img{
        position: static;
        width: 100%;
    }
    .TerminalLinux{
        margin-left: 0;
        margin-right: 0;
    }
    .TerminalLinuxPart03{
        display: flex;
        flex-direction: column;
    }
    .TerminalLinuxInPart03Right{
        position: relative;
        order : 1;
    }
    .TerminalLinuxInPart03Left{
        order: 2;
    }

    .TerminalLinuxPart03>.TerminalLinuxInPart03Right>img{
        position: static;
        width: 100%;
    }
    .TerminalLinuxPart03:after{
        width: 0;
    }
}


@media only screen and (max-width: 1100px) {
    .TerminalLinuxInPart01RightMainImage{
        display: none;
    }
    .TerminalLinuxInPart01Right>.TerminalDesktopComponent3IMG>.TerminalDesktopComponent3IMGImage{
        position: relative;
        left: auto;
        top: auto;
    }
}

@media only screen and (max-width: 920px) {
    .TerminalLinuxInPart01Right>.TerminalDesktopComponent3IMG>.TerminalDesktopComponent3IMGImage>img{
        width: 100%;
    }
    .TerminalLinuxInPart01Right>.TerminalDesktopComponent3IMG>.TerminalDesktopComponent3IMGImage{
        height: auto;
        width: 100%;
        display: block;
        object-fit: contain;
    }

}

.TerminalLinuxInPart03Right>img{
    width: 857px;
}

.TerminalLinuxInPartStore{
    padding-top: 0;
    display: flex;
    align-items: center;
}
.TerminalLinuxInPartStore>.TerminalInPart01LeftStore{
    margin-right: 30px;
}


@media only screen and (max-width: 500px) {
    .TerminalLinuxInPartStore{
        flex-direction: column;
        align-items: center;
    }
}

.TerminalLinuxPart02Left:before{
    content: '';
    height: 100%;
    width: 200px;
    position: absolute;
    top: 0;
    left: -205px;
    z-index: 10;
    background-color: #edf1f1;
}



@media only screen and (min-width: 600px) {
    .TerminalLinuxInPart03Left>.TerminalUl{
        padding-left: 15px;
    }
    .TerminalLinuxInPart03Left>.TerminalTitleH2{
        padding-left: 15px;
    }
}

@media only screen and (max-width: 600px) {
    .TerminalLinuxPart02{
        padding-right: 0;
    }


    .TerminalLinuxInPart02Right{
        position: relative;
        order : 1;
    }
    .TerminalLinuxPart02Left{
        order: 2;
    }
    .TerminalLinuxInPart03Right{
        position: relative;
        order : 2;
    }
    .TerminalLinuxPart03Left{
        order: 1;
    }
}


.TerminalLinuxPart01TextMainSudoOut{
    display: flex;
}
.TerminalLinuxPart01TextMainSudo{
    display: flex;
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-top: 30px;
    margin-bottom: 20px;
    line-height: 27px;
    padding: 11px 30px 11px 16px;
    border: 1px solid #0069d4;
    background-color: #e7e5e5;
    border-radius: 6px;
}
