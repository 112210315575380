.PageTerminal>main{
    max-width: 1058px;
    margin: auto;
}

.PageTerminal>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefMobius>.mobiusTraderLogoBlackActive{
    display: block;
}.PageTerminal>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefMobius>.mobiusTraderLogoBlackNo{
    display: none;
}

.TerminalMenu{
    margin: 25px auto auto;
    max-width: 1080px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.IMGTerminalMenuNoActive{
    display: none;
}

.IMGTerminalMenuHover{
    display: none;
}
.IMGTerminalMenuActive{
    display: none;
}

.TerminalMenuLink:hover>.IMGTerminalMenuHover{
    display: block;
}
.TerminalMenuLink:hover>.IMGTerminalMenu{
    display: none;
}

.TerminalMenuLink>.IMGTerminalMenuNoActive{
    display: none;
}

.TerminalMenuLinkActive>.IMGTerminalMenu{
    display: none;
}
.TerminalMenuLinkActive>.IMGTerminalMenuActive{
    display: block;
}
.TerminalMenuLinkActive:hover>.IMGTerminalMenuHover{
    display: none;
}

.TerminalMenuLinkTo{
    margin-left: 15px;
    margin-top: 15px;
}
.TerminalMenuLink{
    display: flex;
    font-size: 15px;
    align-items: center;
    margin-right: 15px;
    margin-left: 15px;
}
.TerminalMenuLink:hover{
    color: green;
    text-decoration: none;
}

.TerminalMenuLinkActive{
    background-color: #146bb4;
    padding: 5px 15px 5px 0 ;
    border-radius: 22px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.TerminalMenuLinkActive>.TerminalMenuLinkText{
    color: white;
}

.TerminalMenuLinkLineTo{
    color: #bec8d9;
    font-size: 22px;
    text-decoration: none;
    margin-left: 5px;
}

.TerminalMenuLinkTo{
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 800px) {
    .TerminalMenu{
        justify-content: center;
    }
}
