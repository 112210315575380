@keyframes PageContactBackground {
    from {
        background-color: white;
    }

    to {
        background-color: #303030;

    }
}

.PageContact{
    height: 100%;
    overflow: hidden;
    background-color: white;
    animation-name: PageContactBackground;
    animation-delay: 1s;
    animation-fill-mode: forwards;

}

.PageContact>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>a>.MobiusHeaderLogo>.MobiusHeaderLogoText{
    color: white;
}
.PageContact>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>a{
    color: white;
    font-size: 13px;
    font-weight: 500;
}
.PageContact>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLink>.MobiusMenuHrefIn>.MainMenuLinkUnderline{
    background-color: white;
}
.PageContact>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>a>.MainMenuGlobalHrefMobius{
    color: white;
    border-bottom: 1px solid white;
}
.PageContact>header>.MainMenuGlobalAll>.MainMenuGlobalFirstPartOut>.CheckLanguageOutOut>.CheckLanguage>.CheckLanguageTitle{
    color: white;
}



@keyframes ForPageContactElement01 {
    from {
       left: -1000px;
    }

    to {
        left: 0;

    }
}


.PageContactElement01{
    width: 350px;
    height: 423px;
    background-color: rgba(0, 53, 179,0.7);
    color: white;
    padding: 32px;
    position: relative;
    z-index: 25;
    left: -1000px;
    animation-name: ForPageContactElement01;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    margin-bottom: 30px;
}

@keyframes ForPageContactElement01Exit {
    from {
        left: 0;
    }

    to {
        left: -1000px;
    }

    25%{
        left: 100px;
    }
}

.PageContactElement01Exit{
    animation-name: ForPageContactElement01Exit;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}



@keyframes ForPageContactElement02 {
    from {
        right: -1000px;

    }

    to {
        right: 30px;
    }
}

.PageContactElementsAll{
    overflow: hidden;
}



.PageContactElementsAllIn{
    position: relative;
    max-width: 1058px;
    margin: auto;
}

.PageContactElement02{
    width: 414px;
    height: 487px;
    background-color: rgba(0, 53, 179,0.7);
    color: white;
    position: absolute;
    left: auto;
    right: -1000px;
    top: 0;
    z-index: 25;
    animation-name: ForPageContactElement02;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

.TraderFeedbackMainIn>form>.PersonalPageInput{
    width: auto;
    margin-bottom: 15px;
}

@media only screen and (max-width: 1000px) {
    .PageContactElement02{
        position: relative;
        top: 20px;
        margin-left: 30px;
        margin-bottom: 30px;
    }
}


@keyframes ForPageContactElement02Exit {
    from {
        right: 30px;

    }

    to {
        right: -1000px;
    }

    25%{
        right: 100px;
    }
}

.PageContactElement02Exit{
    animation-name: ForPageContactElement02Exit;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.TraderFeedbackMainIn>form{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 32px;
    padding-right: 32px;
}
.TraderFeedbackMainIn>form>.ButtonWhiteOut{
    margin-top: 20px;
}
.TraderFeedbackMainIn>form>.PersonalPageInput>label>.TextPlaceHolder{
    font-weight: 100;
}
.TraderFeedbackMainIn>form>.PersonalPageInput>label>input{
    border-bottom: 1px solid rgba(255,255,255,0.7);
}

.PageContactElementSmall{
    display: flex;
}

.PageContactElementSmall>div{
    display: flex;
    align-items: center;
}

.PageContactElementSmallIMG{
    width: 40px;
}
.PageContactElementSmallIMG2{
    padding-bottom: 16px;
}

.PageContactElement01Title{
    font-weight: 100;
    font-size: 40px;
    color: white;
}
.PageContactElement02Title{
    font-weight: 100;
    font-size: 40px;
    padding-left: 32px;
    padding-top: 32px;
    color: white;
}

.PageContactElement01Phone{
    font-weight: 100;
    font-size: 23px;
    color: white;
    padding-top: 35px;
}

.PageContactElement01Mail{
    font-weight: 100;
    font-size: 23px;
    color: white;
    padding-top: 35px;
}


.PageContactElement01Address{
    font-weight: 100;
    font-size: 19px;
    color: white;
    padding-top: 40px;
    display: block;
}

.PageContactElement02Text{
    padding-top: 25px;
    font-weight: 500;
    line-height: 27px;
}

.PersonalPageInputName>label>input{
    max-width: 246px;
}

.PageContactElement02>.TraderFeedbackMainIn>form>.PersonalPageInput>label>input{
    color: white;
}
.PageContactElement02>.TraderFeedbackMainIn>form>.PersonalPageInput>label>.TextPlaceHolder{
    color: white;
}

.PageContactElement02>.TraderFeedbackMainIn>form>.PersonalPageInput>label>input:not(:placeholder-shown) + span{
    color: #f5b95b;
}


.PageContactCircleFon{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

@keyframes ForPageContactCircleFonIn {
    from {
        width: 400px;
        height: 400px;
        left: 400px;
        top: 400px;
    }

    to {
        width: 4800px;
        height: 4800px;
        left: -1600px;
        top: -1400px;
    }
}


.PageContactCircleFonIn{
    background: url("../../static/img/Contacts/fon.png") center;
    position: absolute;
    height: 400px;
    width: 400px;
    border-radius: 50%;
    left: 400px;
    top: 400px;
    z-index: 1;
    overflow: hidden;
    animation-name: ForPageContactCircleFonIn;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

.PageContact>.PageVacanciesCircleFonInForChange{
    z-index: 30;
}


.PageContact>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>a>.MainMenuGlobalHrefMobius>.mobiusTraderLogoWhiteActive{
    display: block;
}
.PageContact>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>a>.MainMenuGlobalHrefMobius>.mobiusTraderLogoBlackNo{
     display: none;
 }


.PersonalPageInput>label>.TextPlaceHolder {
    position: absolute;
    top: -2px;
    left: 0;
    font-size: 24px;
    color: #190a0d;
    opacity: 0.5;
    font-weight: 300;
    transform-origin: 0 0;
    transition: all .2s ease;
}


.PersonalPageInput>label{
    position: relative;
}

.PersonalPageInput>label>input:hover {
    background-color: #eeeeee;
}

.PersonalPageInput>label>input:focus{
    outline: 0;
    outline-offset: 0;
    background: none;
}

.PersonalPageInput>label>input:focus + span {
    transform: translateY(-14px) scale(.75);
    opacity: 1;
}

.PageContactElementSmall>.PageContactElementAddress{
    flex-wrap: wrap;
    margin-right: 30px;
}

.PersonalPageInput>label>input:not(:placeholder-shown) + span {
    transform: translateY(-14px) scale(.75);
    color: #f5b95b;
    opacity: 1;
}

.PersonalPageInput{
    display: grid;
    position: relative;
    width: 400px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.PersonalPageInput>label>input {
    width: 100%;
    border: 0;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid rgba(25, 10, 13, 0.3);
    background: none;
    transition: all .15s ease;
}

@media only screen and (max-width: 500px) {
    .PageContactElement01{
        width: calc(100% - 20px);
        height: auto;
    }
    .PageContactElement02{
        width: calc(100%);
        height: auto;
    }
    .PageContactElement01{
        padding-right: 0;
    }
    .PageContactElement01Title{
        font-size: 25px;
    }
    .PageContactElementSmall{
        font-size: 16px;
        padding-top: 10px;
    }
    .PageContactElement02>.TraderFeedbackMainIn>form>.PersonalPageInput{
        width: 80%;
    }
    .PageContactElement02>.TraderFeedbackMainIn>form>.PersonalPageInput>label>.TextPlaceHolder{
        font-size: 15px;
    }
}

.TraderFeedbackSuccess>img{
    width: 149px;
    margin: auto;
}

.TraderFeedbackSuccess{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 100px;
}

.TraderFeedbackSuccessTitle{
    padding-top: 35px;
    font-weight: 500;
    font-size: 19px;
    padding-bottom: 10px;
    text-align: center;
}
.TraderFeedbackSuccessText{
    padding-top: 35px;
    font-weight: 100;
    font-size: 19px;
    padding-bottom: 10px;
    text-align: center;
}

.TraderFeedbackSuccessVisible{
    display: flex;
}

@media only screen and (max-width: 1000px) {
    .TraderFeedbackMainIn>form>.ButtonWhiteOut{
        margin-bottom: 30px;

    }
}

@media only screen and (max-width: 1600px) {
    .TraderFeedbackMainIn>form>.PersonalPageInputError>label>.PersonalPageInputErrorText{
        position: absolute;
        display: block;
        top: -35px;
        left: 120px;
        background-image: url("../../static/img/Contacts/ErrorBackPhone.svg");
        background-size: 218px 41px;
        width: 218px;
        height: 41px;
        text-align: center;
        line-height: 35px;
    }
}

.TraderFeedbackMainIn>form>.PersonalPageInput>label>input{
    background: none;
}