@keyframes flash {
    from {
        width: 100px;
        height: 100px;
        border: 2000px solid black;

    }

    to {
        width: 4100px;
        height: 4100px;
        border: 0 solid black;
    }
}

.PageCompany{
    width: 100%;
    min-height: 100%;
    background-color: black;
    position: absolute;
}

.PageCompany>main{
    max-width: 1058px;
    margin: auto;
}


.PageCompany>header>.MainMenuGlobalAll>.MainMenuGlobalFirstPartOut>.MainMenuGlobalFirstPart>.MainMenuGlobalFirstPartBackground>.MainMenuGlobalFirstPartBackgroundStatic{
    opacity: 0.2;
}
.PageCompany>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>a>.MobiusHeaderLogo>.MobiusHeaderLogoText{
    color: #bcddff;
}
@keyframes lineBotttomHref {
    from {
        border-bottom: 0px solid #bcddff;
    }

    to {
        border-bottom: 1px solid #bcddff;
    }
}

.PageCompany>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>a,.PageCompany>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>a:visited{
    color: #bcddff;
    font-size: 13px;
    font-weight: 500;
    animation-name: lineBotttomHref;
    animation-fill-mode: forwards;
    animation-duration: 50ms;
    animation-delay: 1300ms;
}


.PageCompany>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>a:hover{
    color: #20af1b;
}

.PageCompany>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuGlobalLinkActive>.MainMenuLinkUnderline{
    background-color: #bcddff;
}
.PageCompany>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuGlobalLinkActive:hover>.MainMenuLinkUnderline{
    background-color: #20af1b;
}
.PageCompany>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLink>.MobiusMenuHrefIn>.MainMenuLinkUnderline{
    background-color: #bcddff;
}
.PageCompany>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLink:hover>.MainMenuLinkUnderline{
    background-color: #20af1b;
}


.PageCompany>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>a>.MainMenuGlobalHrefMobius{
    color: #1466b4;
    border-bottom: 1px solid #1466b4;
}
.PageCompany>header>div>.MainMenuGlobalHrefsBorder {
    background-color: #bcddff;
}

.PageCompanyCircleFon{
    background-image: url("../../static/img/Company/fon.png");
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
}


.PageCompanyCircleFonIn{
    position: absolute;
    width: 4100px;
    height: 4100px;
    border: 0 solid black;
    animation-name: flash;
    animation-duration: 4s;
    /*animation-duration: 200ms;*/
    border-radius: 50%;
    right: -1500px;
    top: -1700px;
}



.PageCompanyMain{
    display: grid;
    grid-template-columns: 350px auto;
    grid-column-gap: 20px;
    position: relative;
    z-index: 5;
    color: white;
}

.PageCompany>.MainMenuGlobal{
    max-width: 1058px;
}

.PageCompanyMainTitle{
    color: white;
    font-size: 52px;
    text-align: left;
    width: auto;
    font-weight: 100;

}

@keyframes forCompanyTitle01 {
    from {
        left: -800px;
    }

    to {
        left: 0;
    }
}

.PageCompanyMainTitleIn01{
    animation-name: forCompanyTitle01;
    animation-duration: 150ms;
    animation-delay: 1300ms;
    /*animation-delay: 100ms;*/

    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}
@keyframes forCompanyTitle02 {
    from {
        left: -800px;
    }

    to {
        left: 0;
    }
}

.PageCompanyMainTitleIn02{
    animation-name: forCompanyTitle02;
    animation-duration: 150ms;
    animation-delay: 1400ms;
    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}
@keyframes forCompanyTitle03 {
    from {
        left: -800px;
    }

    to {
        left: 0;
    }
}

.PageCompanyMainTitleIn03{
    animation-name: forCompanyTitle03;
    animation-duration: 150ms;
    animation-delay: 1500ms;
    animation-fill-mode: forwards;
    left: -800px;
    position: relative;
}

@keyframes forPageCompanyMainText {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.PageCompanyMainText{
    color: white;
    font-size: 19px;
    text-align: left;
    width: auto;
    font-weight: 100;
    line-height: 25px;
    animation-name: forPageCompanyMainText;
    animation-duration: 500ms;
    animation-delay: 1300ms;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes PageCompanyOurClientsTitleAnimation {
    from {
        color: rgba(256,256,256,0);
        top: -60px;
    }
    to {
        color: rgba(256,256,256,1);
        top: 0;
    }
}

.PageCompanyOurClientsTitle{
    color: rgba(256,256,256,0);
    position: relative;
    font-size: 38px;
    font-weight: 100;
    /*color: white;*/
    top: -60px;
    text-align: center;
    animation-name: PageCompanyOurClientsTitleAnimation;
    animation-duration: 600ms;
    animation-delay: 1700ms;
    animation-fill-mode: forwards;
}


@keyframes PageCompanyOurClientsGridOutAnimation {
    from {
        height: 0;
        margin-top: 0;
    }
    to {
        height: 64px;
        margin-top: 32px;
    }
}

.PageCompanyOurClientsGrid{

    display: flex;
    justify-content: space-around;
    animation-name: PageCompanyOurClientsGridOutAnimation;
    animation-duration: 600ms;
    animation-delay: 1300ms;
    animation-fill-mode: forwards;
}

@keyframes PageCompanyOurClientsGridAnimation {
    from {
        height: 0;
    }
    to {
        height: 64px;
    }
}

.PageCompanyOurClientsGrid>img{
    animation-name: PageCompanyOurClientsGridAnimation;
    height: 0;
    animation-duration: 600ms;
    animation-delay: 1700ms;
    animation-fill-mode: forwards;
}


.PageCompanyOurClientsTitle>b{
    padding-right: 10px;
    padding-left: 10px;
}

.PageCompanyOurClientsOutOut{
    display: flex;
    justify-content: flex-end;
}
.PageCompanyOurClientsOut{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 767px;
    height: 200px;
}


@keyframes PageCompanyOurClientsAnimation {
    from {
        width: 0;
        height: 0;
        border-radius: 0;
        padding: 19px 19px 33px 19px;

        border: rgba(256,256,256,0.2) 1px solid;
    }
    to {
        width: 727px;
        height: auto;
        padding: 19px 19px 33px 19px;

        border-radius: 20px;
        border: rgba(256,256,256,0.2) 1px solid;
    }
}




.PageCompanyOurClients{
    margin: 50px 20px 15px;
    position: relative;
    z-index: 30;

    animation-name: PageCompanyOurClientsAnimation;
    animation-duration: 200ms;
    animation-delay: 1300ms;
    animation-fill-mode: forwards;

}




@media only screen and (max-width: 800px) {
    .PageCompanyOurClientsGrid{
        animation-name: none;
        height: auto;
    }
    .PageCompanyOurClientsGrid>img{
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
    }
    .PageCompanyOurClientsOut{
        height: auto;
    }
}

@media only screen and (max-width: 800px) {


    @keyframes PageCompanyOurClientsAnimation800 {
        from {
            width: 0;
            height: 0;
            padding: 0;
            border-radius: 0;
            border: rgba(256,256,256,0.2) 1px solid;
        }
        to {
            width: auto;
            height: auto;
            padding: 19px 19px 33px 19px;
            border-radius: 20px;
            border: rgba(256,256,256,0.2) 1px solid;
        }
    }


    .PageCompanyOurClientsGrid{
        flex-wrap: wrap;
    }
    .PageCompanyOurClients{
        animation-name: PageCompanyOurClientsAnimation800;
        animation-duration: 600ms;
        animation-delay: 1300ms;
        animation-fill-mode: forwards;
        width: auto;
    }
}

@media only screen and (max-width: 500px) {
    .PageCompanyOurClientsTitle{
        font-size: 30px;
    }
    .PageCompanyOurClientsGrid>img{
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
    }
}



@media only screen and (max-width: 420px) {
    .PageCompanyOurClientsTitle{
        font-size: 16px;
    }
}




.PageCompany>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>a>.MainMenuGlobalHrefMobius>.mobiusTraderLogoWhiteActive{
    display: block;
}
.PageCompany>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>a>.MainMenuGlobalHrefMobius>.mobiusTraderLogoBlackNo{
    display: none;
}

@media only screen and (max-width: 900px) {
    .PageCompanyMain {
        display: block;
    }
    .PageCompanyMainTitle{
        padding-bottom: 30px;
    }
}

@media only screen and (max-width: 500px) {
    .PageCompanyMainTitle{
        font-size: 30px;
    }
}
