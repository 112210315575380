.TerminalMacOs{
    font-family: Geometria, sans-serif;
}
.TerminalMacOsIn{
    background-color: white;
    max-width: 1078px;
    margin: auto;
}

.TerminalMacOsPart01Top{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.TerminalMacOsPart01Top>img{
    padding-top: 10px;

}

.TerminalMacOsInPart01Right{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TerminalMacOsInPart01Right>.TerminalWebComponent3IMG>.TerminalWebComponent3IMGCircles{
    margin-bottom: 30px;
}

@media only screen and (max-width: 970px) {
    .TerminalMacOsInPart01Right>.TerminalWebComponent3IMG>.TerminalWebComponent3IMGImage>.TerminalWebComponent3IMGImageIn>img{
        width: 100%;
        object-fit: contain;
    }
}

@media only screen and (max-width: 700px) {
    .TerminalMacOsPart01Top{
        justify-content: center;
    }
    .TerminalWeb>.TerminalIn>.TerminalMacOsPart01{
        padding-right: 10px;
        padding-left: 10px;
    }
    .TerminalInPart01LeftText{
        padding-right:  20px;
    }
}

.TerminalMacOsInPartStore{
    padding-top: 0;
}

.TerminalMacOsInPart01RightMainImage{
    position: relative;
}


.TerminalMacOsInPartStore>.TerminalInPart01LeftStore,.TerminalMacOsInPartStore>.TerminalInPart01LeftText{
    padding-bottom: 10px;
}

@media only screen and (max-width: 400px) {
    .TerminalMacOsPart01Top>img{
        display: none;
    }
}

.TerminalMacOsPart01TextMain{
    padding-bottom: 20px;
}

.TerminalMacOsPart02Out{
    background-color: #f2f2ee;
}

.TerminalMacOsPart02{
    display: grid;
    grid-template-columns: 600px auto;
    grid-column-gap: 40px;
    padding-top: 40px;
}

.TerminalMacOsPart02>.TerminalMacOsPart02Left>img{
    object-fit: contain;
}

.TerminalMacOsPart03{
    display: grid;
    grid-template-columns: auto 600px;
    grid-column-gap: 40px;
    padding-top: 40px;
}

.TerminalMacOsPart03>.TerminalMacOsInPart03Right>img{
    object-fit: contain;
}


.TerminalMacOsPart02Left,.TerminalMacOsInPart03Right{
    position: relative;
    overflow: hidden;
}

    .TerminalMacOsPart02{
        padding-left: 0;
        grid-template-columns: auto 300px;
        position: relative;
    }
    .TerminalMacOsPart02>.TerminalMacOsPart02Left>img{
        position: absolute;
        right: -3px;
    }
    .TerminalMacOsPart02:before{
        content: '';
        height: 100%;
        width: 500px;
        position: absolute;
        top: 0;
        left: -500px;
        z-index: 10;
        background-color: #edf1f1;
    }
    .TerminalMacOsPart03{
        padding-right: 0;
        padding-left: 0;
        grid-template-columns: 300px auto;
        position: relative;
    }
    .TerminalMacOsPart03>.TerminalMacOsInPart03Right{overflow: hidden;}


    .TerminalMacOsPart03>.TerminalMacOsInPart03Right>img{
        position: absolute;
        left: -3px;
        overflow: hidden;

    }

.TerminalMacOsInPart02Right>ul,.TerminalMacOsInPart03Left>ul{
    padding: 0;
}

.TerminalMacOsInPart02RightAndroidDesktop>.TerminalTitleH2{
    margin-top: 40px;
}


.TerminalMacOsInPart02Right>ul>li,.TerminalMacOsInPart03Left>ul>li{
    line-height: 27px;
    color: #413422;
    font-size: 16px;
    list-style: none;
    margin-top: 25px;
}

.TerminalMacOsInPart02Right>ul>li:before,.TerminalMacOsInPart03Left>ul>li:before{
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #ff9306;
    margin-right: 10px;
    margin-top: 5px;
    display: block;
    float: left;
    position: relative;
    top: 3px;
}

.TerminalMacOsInPart02Right>.TerminalTitleH2,.TerminalMacOsInPart03Left>.TerminalTitleH2{
    padding: 0 30px 0 0;
    font-size: 24px;
    line-height: 28px;
    color: #413422;
}

@media only screen and (max-width: 600px) {

    .TerminalMacOsInPart02Right>.TerminalTitleH2,.TerminalMacOsInPart03Left>.TerminalTitleH2{
        padding-top: 20px;
    }
    .TerminalMacOsPart02{
        display: flex;
        flex-direction: column;
        background-color: white;
        padding-right: 0;
    }
    .TerminalMacOsInPart02Right,.TerminalMacOsInPart03Left{
        padding-left: 20px;
        padding-right: 20px;
    }
    .TerminalMacOsPart02>.TerminalMacOsPart02Left>img{
        position: static;
        width: 100%;
    }
    .TerminalMacOs{
        margin-left: 0;
        margin-right: 0;
    }
    .TerminalMacOsPart03{
        display: flex;
        flex-direction: column;
    }
    .TerminalMacOsInPart03Right{
        position: relative;
        order : 1;
    }
    .TerminalMacOsInPart03Left{
        order: 2;
    }

    .TerminalMacOsPart03>.TerminalMacOsInPart03Right>img{
        position: static;
        width: 100%;
    }
    .TerminalMacOsPart03:after{
        width: 0;
    }
}


.TerminalInPart01LeftStore{
    display: flex;
}

.TerminalInPart01LeftText{
    font-size: 16px;
}

.TerminalMacOsPart01TextMain{
    padding-bottom: 20px;
    font-weight: 400;
    font-size: 18px;
    color: #413422;
    padding-top: 30px;
    line-height: 27px;
}

.TerminalMacOsInPart01Right>.TerminalDesktopComponent3IMG>.TerminalDesktopComponent3IMGImage{
    left: -405px;
    top: 120px;
}


@media only screen and (max-width: 1000px) {
    .TerminalMacOsInPart01RightMainImage{
        display: none;
    }
    .TerminalMacOsInPart01Right>.TerminalDesktopComponent3IMG>.TerminalDesktopComponent3IMGImage{
        position: relative;
        left: auto;
        top: auto;
    }
}

@media only screen and (max-width: 920px) {
    .TerminalMacOsInPart01Right>.TerminalDesktopComponent3IMG>.TerminalDesktopComponent3IMGImage>img{
        width: 100%;
    }
    .TerminalMacOsInPart01Right>.TerminalDesktopComponent3IMG>.TerminalDesktopComponent3IMGImage{
        height: auto;
        width: 100%;
        display: block;
        object-fit: contain;
    }

}

.TerminalMacOsInPart03Right>img{
    width: 857px;
}

.TerminalMacOsInPartStore{
    padding-top: 0;
    display: flex;
    align-items: center;
}
.TerminalMacOsInPartStore>.TerminalInPart01LeftStore{
    margin-right: 30px;
}

@media only screen and (max-width: 500px) {
    .TerminalMacOsInPartStore{
        flex-direction: column;
        align-items: center;
    }
}

.TerminalMacOsPart02Left:before{
    content: '';
    height: 100%;
    width: 200px;
    position: absolute;
    top: 0;
    left: -205px;
    z-index: 10;
    background-color: #edf1f1;
}


@media only screen and (min-width: 600px) {
    .TerminalMacOsInPart03Left>.TerminalUl{
        padding-left: 15px;
    }
    .TerminalMacOsInPart03Left>.TerminalTitleH2{
        padding-left: 15px;
    }

}
@media only screen and (max-width: 600px) {
    .TerminalMacOsPart02{
        padding-right: 0;
    }


    .TerminalMacOsInPart02Right{
        position: relative;
        order : 1;
    }
    .TerminalMacOsPart02Left{
        order: 2;
    }
    .TerminalMacOsInPart03Right{
        position: relative;
        order : 2;
    }
    .TerminalMacOsPart03Left{
        order: 1;
    }
}
