@media only screen and (max-width: 700px) {
    .PersonalPageInputBottomLangCodeIFRAME{
        display: none;
    }
}
@media only screen and (max-width: 560px) {
    .PersonalPageInputBottomLangCodeIFRAME{
        display: none;
    }
    .PageAdvantageBottomPassword>img{
        width: calc(100% - 10px);
    }
}
@media only screen and (max-width: 600px) {
    .PersonalPageInput{
        width: auto;
    }
    .PersonalPageInputBottomLangCode{
        font-size: 14px;
    }
}