/*Gotham*/
@font-face {
    font-family: 'GothamPro';
    src:
    url('./Gothampro/GothamPro-Black/GothamPro-Black.eot?') format('eot'),
    url('./Gothampro/GothamPro-Black/GothamPro-Black.otf')  format('opentype'),
    url('./Gothampro/GothamPro-Black/GothamPro-Black.woff') format('woff'),
    url('./Gothampro/GothamPro-Black/GothamPro-Black.ttf')  format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'GothamPro';
    src:
    url('./Gothampro/GothamPro-BlackItalic/GothamPro-BlackItalic.eot?') format('eot'),
    url('./Gothampro/GothamPro-BlackItalic/GothamPro-BlackItalic.otf')  format('opentype'),
    url('./Gothampro/GothamPro-BlackItalic/GothamPro-BlackItalic.woff') format('woff'),
    url('./Gothampro/GothamPro-BlackItalic/GothamPro-BlackItalic.ttf')  format('truetype');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'GothamPro';
    src:
    url('./Gothampro/GothamPro-Bold/GothamPro-Bold.eot?') format('eot'),
    url('./Gothampro/GothamPro-Bold/GothamPro-Bold.otf')  format('opentype'),
    url('./Gothampro/GothamPro-Bold/GothamPro-Bold.woff') format('woff'),
    url('./Gothampro/GothamPro-Bold/GothamPro-Bold.ttf')  format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'GothamPro';
    src:
    url('./Gothampro/GothamPro-BoldItalic/GothamPro-BoldItalic.eot?') format('eot'),
    url('./Gothampro/GothamPro-BoldItalic/GothamPro-BoldItalic.otf')  format('opentype'),
    url('./Gothampro/GothamPro-BoldItalic/GothamPro-BoldItalic.woff') format('woff'),
    url('./Gothampro/GothamPro-BoldItalic/GothamPro-BoldItalic.ttf')  format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'GothamPro';
    src:
    url('./Gothampro/GothamPro-Medium/GothamPro-Medium.eot?') format('eot'),
    url('./Gothampro/GothamPro-Medium/GothamPro-Medium.otf')  format('opentype'),
    url('./Gothampro/GothamPro-Medium/GothamPro-Medium.woff') format('woff'),
    url('./Gothampro/GothamPro-Medium/GothamPro-Medium.ttf')  format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'GothamPro';
    src:
    url('./Gothampro/GothamPro-MediumItalic/GothamPro-MediumItalic.eot?') format('eot'),
    url('./Gothampro/GothamPro-MediumItalic/GothamPro-MediumItalic.otf')  format('opentype'),
    url('./Gothampro/GothamPro-MediumItalic/GothamPro-MediumItalic.woff') format('woff'),
    url('./Gothampro/GothamPro-MediumItalic/GothamPro-MediumItalic.ttf')  format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'GothamPro';
    src:
    url('./Gothampro/Gothampro/GothamPro.eot?') format('eot'),
    url('./Gothampro/Gothampro/GothamPro.otf')  format('opentype'),
    url('./Gothampro/Gothampro/GothamPro.woff') format('woff'),
    url('./Gothampro/Gothampro/GothamPro.ttf')  format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'GothamPro';
    src:
    url('./Gothampro/GothamPro-Italic/GothamPro-Italic.eot?') format('eot'),
    url('./Gothampro/GothamPro-Italic/GothamPro-Italic.otf')  format('opentype'),
    url('./Gothampro/GothamPro-Italic/GothamPro-Italic.woff') format('woff'),
    url('./Gothampro/GothamPro-Italic/GothamPro-Italic.ttf')  format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'GothamPro';
    src:
    url('./Gothampro/GothamProNarrow-Bold/GothamProNarrow-Bold.eot?') format('eot'),
    url('./Gothampro/GothamProNarrow-Bold/GothamProNarrow-Bold.otf')  format('opentype'),
    url('./Gothampro/GothamProNarrow-Bold/GothamProNarrow-Bold.woff') format('woff'),
    url('./Gothampro/GothamProNarrow-Bold/GothamProNarrow-Bold.ttf')  format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'GothamPro';
    src:
    url('./Gothampro/GothamProNarrow-Medium/GothamProNarrow-Medium.eot?') format('eot'),
    url('./Gothampro/GothamProNarrow-Medium/GothamProNarrow-Medium.otf')  format('opentype'),
    url('./Gothampro/GothamProNarrow-Medium/GothamProNarrow-Medium.woff') format('woff'),
    url('./Gothampro/GothamProNarrow-Medium/GothamProNarrow-Medium.ttf')  format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'GothamPro';
    src:
    url('./Gothampro/GothamPro-Light/GothamPro-Light.eot?') format('eot'),
    url('./Gothampro/GothamPro-Light/GothamPro-Light.otf')  format('opentype'),
    url('./Gothampro/GothamPro-Light/GothamPro-Light.woff') format('woff'),
    url('./Gothampro/GothamPro-Light/GothamPro-Light.ttf')  format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'GothamPro';
    src:
    url('./Gothampro/GothamPro-LightItalic/GothamPro-LightItalic.eot?') format('eot'),
    url('./Gothampro/GothamPro-LightItalic/GothamPro-LightItalic.otf')  format('opentype'),
    url('./Gothampro/GothamPro-LightItalic/GothamPro-LightItalic.woff') format('woff'),
    url('./Gothampro/GothamPro-LightItalic/GothamPro-LightItalic.ttf')  format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
/*'Roboto'*/
@font-face {
    font-display: swap;    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Black.ttf');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-display: swap;    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-BlackItalic.ttf');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-display: swap;    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-display: swap;    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-display: swap;    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-display: swap;    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-display: swap;    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: swap;    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-display: swap;    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Thin.ttf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-display: swap;    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-ThinItalic.ttf');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-display: swap;    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Light.ttf');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-display: swap;    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-LightItalic.ttf');
    font-weight: 100;
    font-style: italic;
}
