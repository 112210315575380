
.PageCompanyNews{
    position: relative;
    z-index: 5;
    overflow: hidden;
}

.PageCompanyNewsTop{
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    animation-name: forPageCompanyMainText;
    animation-duration: 500ms;
    animation-delay: 1300ms;
    animation-fill-mode: forwards;
    opacity: 0;
}

.PageCompanyNewsTopTitle{
    color: white;
    font-size: 41px;
    text-align: left;
    font-weight: 100;
}

.PageCompanyNewsMain{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
}

@keyframes forPageCompanyNewsMainElement {
    from {
        top: 600px;
    }

    to {
        top: 0;
    }
}

.PageCompanyNewsMainElement{
    padding-right: 50px;
    animation-name: forPageCompanyNewsMainElement;
    animation-duration: 1600ms;
    animation-delay: 900ms;
    animation-fill-mode: forwards;
    position: relative;
    top: 600px;
}

@keyframes forPageCompanyNewsTopButton {
    from {
        right: -600px;
    }
    to {
        right: 0;
    }
}

.PageCompanyNewsTopButton{
    padding-right: 50px;
    animation-name: forPageCompanyNewsTopButton;
    animation-duration: 1600ms;
    animation-delay: 900ms;
    animation-fill-mode: forwards;
    position: relative;
    right: -600px;
}


.PageCompanyNewsMainElement:nth-of-type(2){
    animation-delay: 1500ms;
}
.PageCompanyNewsMainElement:nth-of-type(3){
    animation-delay: 1800ms;
}

.PageCompanyNewsMainElementDate{
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding-bottom: 10px;
    line-height: 28px;
}

.PageCompanyNewsMainElementTitle{
    color: #bcddff;
    font-size: 17px;
    font-weight: 400;
    padding-bottom: 10px;
    line-height: 25px;
}

.PageCompanyNewsMainElementText{
    color: white;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
}

@media only screen and (max-width: 900px) {

    .PageCompanyNewsMainElement{
        padding-right: 10px;
    }
    .PageCompanyNews{
        margin-top: 40px;
    }
}


.PageCompanyNewsCircles{
    display: none;
}
.PageCompanyNewsCircles>div{
    width: 48px;
    height: 48px;
    opacity: 0.2;
    background-color: white;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.PageCompanyNewsCircles>.NewsActive{
    opacity: 0.7;
    cursor: auto;
}

@media only screen and (max-width: 800px) {
    .PageCompanyNewsMainElement:nth-of-type(3){
        display: none;
    }
    .PageCompanyNewsMain{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 500px) {
    .PageCompanyNewsMainElement{
        animation-name : none;
        top: 0;
    }
    .PageCompanyNewsTop{
        padding-bottom: 10px;
    }

    .PageCompanyNewsMainElementText{
        padding-bottom: 15px;
    }
    .PageCompanyNewsMainElement:nth-of-type(2){
        display: none;
    }
    .PageCompanyNewsMain{
        display: block;
    }
    .PageCompanyNewsCircles{
        display: flex;
        justify-content: center;
        padding-bottom: 18px;
        position: relative;
        z-index: 6;
    }
    .PageCompanyNewsMain>.NewsActiveNot{
        display: none;
    }
    .PageCompanyNewsMain>.NewsActive{
        display: block;
    }
}