.PageGeneralOptions>.PageMainPartBig{
    max-width: 877px;
}

.ActivationGeneralOptions01{
    padding-top: 50px;
}

.ActivationGeneralOptionsTopIcon{
    padding-top: 40px;
    padding-bottom: 40px;
}

.ActivationGeneralOptionsSmallTitle{
    font-weight: 500;
    font-size: 18px;
    color: black;
    line-height: normal;
    padding-bottom: 12px;
}



.ActivationGeneralOptionsViewNotActive>.ActivationGeneralOptionsViewNotAllInfo{
    display: block;
}

.ActivationGeneralOptionsViewNotTitle{
    font-weight: 500;
    font-size: 18px;
    color: #0247be;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 15px;
    display: flex;
    cursor: pointer;
    padding-left: 30px;
}
.ActivationGeneralOptionsViewNotTitle>span{
    border-bottom: 2px dashed #0247be;
}

.ActivationGeneralOptionsStandartElementForPadding{
    padding-top: 35px;
    padding-bottom: 35px;
}



.ActivationGeneralOptionsViewNot{

}

.ActivationGeneralOptionsViewNotTextElements{
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    font-weight: 500;
    font-size: 18px;
    color: black;
    line-height: 24px;
    margin-bottom: 15px;
}

.ActivationGeneralOptionsViewNotTextElement{
    width: 100px;
    text-align: center;
}

.ActivationGeneralOptionsViewNotAllInfo{
    background-color: rgba(116,164,247,0.5);
    margin-left: 14px;
    padding: 16px;
    display: none;
}

.ActivationGeneralOptionsViewNotTextInfo>b{
    margin-left: 5px;
    margin-right: 5px;
}

.ActivationGeneralOptionsAttention>div>span{
    font-weight: 700;
    padding-right: 5px;
}

.ActivationGeneralOptionsAttention{
    margin-left: 32px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.ActivationGeneralOptionsAttention>img{
    padding-right: 16px;
}