.ActivationSSL01{
    padding-top: 30px;

}
.ActivationSSL010{
    padding-top: 30px;
    padding-bottom: 10px;

}

.ActivationSiteSmallTitle{
    font-weight: 500;
    font-size: 18px;
    color: black;
    line-height: normal;
    padding-bottom: 12px;
}

.ActivationSSL02{
    padding-top: 30px;
}
.ActivationSSL03{
    padding-top: 30px;
    padding-bottom: 30px;
}

.ActivationSSL05{
    padding-top: 30px;
}

.ActivationSSL>.ActivationSiteAttention {
    margin-top: 10px;
    margin-bottom: 20px;
}

.ActivationSiteAttention{
    font-weight: 500;
    font-size: 18px;
    color: black;
}

.StandartTextActivation>a{
    padding-left: 6px;
}