.CRMIntegrationH1{
    font-weight: 500;
    color: #020202;
    font-size: 26px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.CRMIntegrationH2{
    color: #020202;
    font-size: 18px;
}

.CRMIntegrationIMG01div{
    display: flex;
    justify-content: center;
}
.CRMIntegrationIMG01{
    width: 80%;
    margin: 20px auto auto;
}

.CRMIntegrationGrid01{
    display: grid;
    grid-template-columns: 180px auto;
}

.CRMIntegrationGrid02{
    display: grid;
    grid-template-columns: 3fr 2fr;
}

.CRMIntegrationLiElement{
    display: grid;
    grid-template-columns: 20px auto;
    margin-top: 20px;
    font-weight: 500;
    color: #020202;
    font-size: 18px;
}

.CRMIntegrationLiElementSmall{
    font-weight: 400;
}

@media only screen and (max-width: 600px) {
    .CRMIntegrationGrid02{
        display: block;
    }
    .CRMIntegrationGrid01{
        display: block;
    }
}