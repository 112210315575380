.ActivationSetSettings01{
    padding-top: 50px;

}

.ActivationSetSettingsBlue{
    color: #0079bf;
    padding-right: 10px;
    padding-left: 10px;
}

.ActivationSetSettings>div>div{
    padding-bottom: 20px;

}

.ActivationSetSettings01>img{
    padding-top: 15px;
    padding-bottom: 15px;
}