.NewElementDate{
    font-size: 13px;
    color: #151e27;
    font-weight: 700;
    padding-bottom: 10px;
}

.NewElementTitle{
    font-size: 19px;
    color: #1466b4;
    font-weight: 400;
    line-height: 30px;
    text-decoration: underline;
    cursor: pointer;
}

.NewNotActive>.NewElementText{
    display: none;
}

.NewActive{
    position: relative;
}

.NewActive:before{
    content: '';
    width: 11px;
    top: 0;
    bottom: 0;
    position: absolute;
    left: -45px;
    background: #eff4f9;
}

.NewActive>.NewElementTitle{
    font-size: 19px;
    color: black;
    font-weight: 700;
    text-decoration: none;
    padding-bottom: 10px;
}

.NewElementText{
    font-size: 15px;
    line-height: 26px;
}

.NewElement{
    margin-bottom: 60px;
    position: relative;
}