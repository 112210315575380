
.FromOtherSystems{
    margin-bottom: 50px;
}

.ActivationFromOtherSystems01{
    padding-top: 50px;
}

.FromOtherSystems>.StandartTitleActivationH2{
    padding-top: 15px;
}

.FromOtherSystemsMainGrid{
    display: grid;
    grid-template-columns: 30px auto;
    grid-column-gap: 18px;
}

.FromOtherSystemsMainGridNumber{
    border: 1px solid black;
    border-radius: 15px;
    font-size: 20px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin-top: -4px;
}
.FromOtherSystemsMainGridText{
    font-size: 20px;
    line-height: 22px;
}

.FromOtherSystemsMainGrid{
    padding-top: 30px;
}

.FromOtherSystemH2{
    font-weight: 700;
    font-size: 24px;
    color: black;
    padding-top: 20px;
    padding-bottom: 0;
}

.FromOtherSystemsMainGridText>b{
    margin-left: 5px;
    margin-right: 5px;
}