

body{
    margin: 0;
    font-family: GothamPro, sans-serif ;
}

div, input, button, textarea, span{
    outline:none;
}

div>.isNoVisible,.isNoVisible{
    display: none;
}

h1{
    margin: unset;
    margin-inline: unset;
    margin-block: unset;
}


.ButtonOut{
    display: flex;
}

a,a:visited{
    color: #1466b4;
}


.StandartIn{
    padding-left: 30px;
    padding-right: 30px;
}


.StandartTitle{
    font-family: Geometria, sans-serif ;
    font-weight: 800;
    font-size: 30px;
    color: #413422;
    line-height: 30px;
    padding: 37px 0 37px 0;
}

.StandartText{
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    color: #151e27;
    line-height: 35px;
}

.StandartH2{
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    color: black;
}


@media only screen and (max-width: 900px) {
    .StandartIn{
        padding-left: 20px;
        padding-right: 30px;
    }
    .StandartIn>.StandartIn{
        padding-left: 0;
        padding-right: 0;
    }
}



@media only screen and (min-width: 500px) {
    .StandartPhone {
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    .StandartNoPhone {
        display: none;
    }
}

@media only screen and (max-width: 700px) {
    .StandartIn{
        padding-right: 20px;
    }
}

@media only screen and (max-width: 500px) {
    .StandartIn{
        padding-right: 10px;
        padding-left: 10px;
    }
}

.PageMainPart{
    font-family: GothamPro, sans-serif;
    display: grid;
    grid-template-columns: 348px auto;
}


@media only screen and (max-width: 1000px) {
    .PageMainPart{
        display: block;
    }
}


.StandartTitleWidget{
    font-weight: 400;
    font-style: normal;
    font-size: 52px;
    color: black;
    text-align: left;
    padding-bottom: 25px;
}
.StandartTextWidget{
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    color: black;
    line-height: 32px;
}

.ButtonStandartOut>a{
    text-decoration: none;
}


.ButtonTerminalPhoneOnly{
    height: 100%;
    width: 100px;
    color: rgba(256,256,256,0);
}
.ButtonTerminalTabOnly{
    height: 100%;
    width: 100px;
    color: rgba(256,256,256,0);
}

.ButtonGreen{
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    height: 42px;
    padding-left: 36px;
    padding-right: 36px;
    line-height: 42px;
    min-width: 185px;
    border-radius: 100px;
    background-color: #2daa2b;
    border: 2px solid #2daa2b;
    color: white;
    text-align: center;
    cursor: pointer;
}

.ButtonGreen:hover{
    background-color: white;
    color: black;
    transition: all .2s linear;
}

.ButtonGreen:active{
    background-color:  black;
    color: white;
    border-color:  black;
}

.ButtonSend{
    padding: 6px 30px 6px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    border: solid 2px white;
    background-color: unset;
    font-size: 30px;
    text-align: center;
    cursor: pointer;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: color 1000ms;
    transition: color 1000ms;
}

.ButtonSend:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #151e27;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out;
    color: white;
}

.ButtonSend>div{
    color: white;
}

.ButtonSend:hover{
    transition: all .3s linear;
}

.ButtonSend:hover:before{
    transform: scaleY(1);

}


.ButtonSend:active:before{
    top: 160px;
    transform: scaleY(1);
    transform-origin: 50% 0;
    transition: all .6s linear;
}

.ButtonSend:active{
    color: black;
    transition: all .3s linear;
}

.ButtonWhiteOut>a{
    text-decoration: none;
}
.ButtonWhite{
    padding: 10px 30px 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    border: solid 2px #151e27;
    background-color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: color 1000ms;
    transition: color 1000ms;
    color: #1e1e1e;
    min-width: 128px;
    height: 58px;
}

.ButtonWhite:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #151e27;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out;
}


.ButtonWhite:hover{
    color: white;
    transition: all .3s linear;
}

.ButtonWhite:hover:before{
    transform: scaleY(1);

}


.ButtonWhite:active:before{
    top: 160px;
    transform: scaleY(1);
    transform-origin: 50% 0;
    transition: all .6s linear;
}

.ButtonWhite:active{
    color: black;
    transition: all .3s linear;
}


.ButtonBlue{
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 46px;
    min-width: 185px;
    border-radius: 100px;
    background-color: white;
    border: 2px solid #1466b4;
    color: #1466b4;
    text-align: center;
    cursor: pointer;
}

.ButtonBlue:hover{
    background-color: #1466b4;
    color: white;
    transition: all .2s linear;
}

.ButtonBlue:active{
    background-color:  black;
    color: white;
    border-color:  black;
}