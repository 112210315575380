.PersonalPageInputBottomLangTitle01{
    font-size: 24px;
    font-weight: 400;
    color: #151e27;
}


.CheckLanguageImg{
    position: relative;
    top: 1px;
}

.CheckLanguageTitle{
    position: relative;
    top: -1px;
}

.PersonalPageInputBottomLangTitle{
    font-size: 24px;
    font-weight: 400;
    color: #6f6f6f;

}

.PersonalPagePadding3{
    padding-bottom: 10px;
}
.PersonalPagePadding4{
    padding-top: 35px;
    padding-bottom: 25px;
}

.PersonalPageInputBottomLangTitleCode{
    padding-top: 25px;
}

.PersonalPageInputBottomLangCode{
    border: solid 1px black;
}

.PersonalPageInputBottomLangCode{
    padding: 15px;
}

.PageMainPartBig>.StandartTextWidget>span>.ButtonOut{
    margin-top: 15px;
}

.PersonalPageInputBottomBottom{
    padding-top: 25px;
}