.ActivationMobiusIndexTopGrid{
    display: grid;
    grid-template-columns: 150px auto;
    padding: 60px;
    background-color: #2678ff;
    margin-top: 45px;
}

.ActivationMobiusIndexTopGridText{
    color: white;
    line-height: 43px;
    font-size: 24px;
}
.ActivationMobiusIndexTopGridText>b{
    margin-left: 5px;
    margin-right: 5px;
}
.ActivationMobiusIndexTextBottom{
    background-color: rgba(247, 250, 255, 0.42);
    font-weight: 100;
    line-height: 27px;
    color: black;
    padding: 60px;
    margin-bottom: 40px;
}

@keyframes PageMainPartBig {
    from {
        top: -50px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

.PageMainPartBig2{
    animation-name: PageMainPartBig;
    animation-duration: 1000ms;
    animation-delay: 300ms;
    animation-fill-mode: forwards;
    top: -50px;
    opacity: 0;
    position: relative;
}