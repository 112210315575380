.iPadTerminal{
    font-family: Geometria, sans-serif;
}
.iPadTerminalTitle{
    font-weight: 700;
    font-size: 30px;
    color: #413422;
    padding-top: 8px;
}


.iPadTerminalPadInPart01RightMainImage{
    margin-top: 50px;
}

.iPadTerminalPadInPart01RightMainImage>img{

    position: relative;
    left: -1px;
    top: -51px;
}

.iPadTerminalInPart01LeftStore>a>img{
    height: 50px;
}

.iPadTerminalInPart01{
    display: grid;
    grid-template-columns: 3fr 1fr auto;
    grid-template-rows: 50px 120px;
    padding-top: 30px;
    min-height: 600px;
}


.iPadTerminalTitle{
    grid-column: 1/span 3;
    grid-row: 1 ;
}

.iPadTerminalInTitleGridButtons{
    grid-column: 3;
    grid-row: 1;
    display: flex;
    background: url("../../static/img/Terminal/TerminalTablet.svg") no-repeat;
    width: 245px;
    height: 42px;
    justify-self: flex-end;
}


.iPadTerminalInPart01Left{
    grid-column: 1;
    grid-row: 3;
    padding-bottom: 20px;
}


.iPadTerminalInPart02{
    padding-top: 40px;
}




.iPadTerminalInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGImage{
    position: absolute;
    top: 132px;
    left: 43px;
}

.iPadTerminalInPartStore{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-column: 1 /span 3;
    grid-row: 2;

}

.iPadTerminalInPart01LeftStore>img{
    height: 50px;
}

.iPadTerminalInPart01LeftText{
    font-weight: 400;
    font-style: italic;
    font-size: 18px;
    color: #413422;
    padding-left: 26px;
}

.iPadTerminalInPart01Right{
    grid-column: 3;
    grid-row: 2 /span 3;
    position: relative;
    left: -40px;
}

.iPadTerminalInPart01Right>img{
    margin-top: 20px;
    position: relative;
    top: 60px;
}
@media only screen and (max-width: 1150px) {
    .iPadTerminalInPart01Right{
        left: -50px;
    }
}
@media only screen and (max-width: 1050px) {
    .iPadTerminalInPartStore{
        grid-column: 1 /span 3;
        grid-row: 2;
    }



}
@media only screen and (max-width: 950px) {
    .iPadTerminalInPart01Right {
        grid-row: 3;
        position: relative;

    }
}


.iPadTerminalInPart01LeftTextMain{
    font-weight: 400;
    font-size: 18px;
    color: #413422;
    line-height: 30px;
}



.iPadTerminalInPart02{
    background-color: #f3f3ef;
    height: 518px;
    position: relative;
    display: grid;
    grid-template-columns: 4fr 3fr;
    grid-column-gap: 66px;}

.iPadTerminalInPart01RightMainImage{
    position: absolute;
    top: 60px;
    left: -6px;

}

.iPadTerminalInPart02Left>img{
    position: relative;
    right: 0;
}
.iPadTerminalInPart03Right{
    position: relative;
}

.iPadTerminalInPart02Right>ul,.iPadTerminalInPart03Left>ul{
    padding: 0;
    padding-right: 15px;
}

.iPadTerminalInPart02RightiPhoneDesktop>.iPadTerminalTitleH2{
    margin-top: 40px;
}


.iPadTerminalInPart02Right>ul>li,.iPadTerminalInPart03Left>ul>li{
    line-height: 27px;
    color: #413422;
    font-size: 16px;
    list-style: none;
    margin-top: 25px;
}

.iPadTerminalInPart02Right>ul>li:before,.iPadTerminalInPart03Left>ul>li:before{
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #ff9306;
    margin-right: 10px;
    margin-top: 5px;
    display: block;
    float: left;
    position: relative;
    top: 3px;
}

.iPadTerminalInPart02Right>.iPadTerminalTitleH2,.iPadTerminalInPart03Left>.iPadTerminalTitleH2{
    padding: 0 30px 0 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #413422;
}

.iPadTerminalInPart03{
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 50px;
    grid-template-rows: auto;
    padding-top: 37px;
    padding-bottom: 30px;
}

.iPadTerminalInPart03Right{
    height: 100%;
}

.iPadTerminalInPart03Right>img{
    height: auto;
}


@media only screen and (max-width: 1050px) {
    .iPadTerminalInPart02{
        height: auto;
    }
    .iPadTerminalInPart02Left>img{
        top: 0;
    }

}
@media only screen and (max-width: 900px) {

    .iPadTerminalInPart03{
        margin-top: 0;}
}


@media only screen and (max-width: 900px) {

    .iPadTerminalInPart01LeftTextMain{
        font-size: 18px;
    }
    .iPadTerminaliPhone>.iPadTerminalIn>.iPadTerminalInPart01>.iPadTerminalInPart01Right{
        grid-column: 1/span 3;
    }
    .iPadTerminalInPart03Right{
        height: auto;
    }
    .TerminalAndroidInPart01RightMainImage{
        display: none;
    }
    .iPadTerminalInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGImage{
        position: static;
    }

}


@media only screen and (max-width: 700px) {
    .iPadTerminalInPart02Right,.iPadTerminalInPart03Left,.iPadTerminalInPart01LeftTextMain{
        padding-right: 10px;
        padding-left: 10px;
    }
    .iPadTerminalInPartStore{
        justify-content: center;
    }

    .iPadTerminalInPart01{
        grid-template-columns: auto;
        height: auto;
    }
    .iPadTerminalTitle{
        grid-column: 1;
        grid-row: 1 ;
        text-align: center;
    }

    .iPadTerminalInPartStore{
        grid-column: 1;
        grid-row: 3;
    }

    .iPadTerminalInTitleGridButtons{
        flex-direction: row;
        grid-column: 1;
        grid-row: 2;
        justify-self: center;
        margin-top: 10px;

    }

    .iPadTerminalInPart01RightMainImage{
        display: none;
    }


    .iPadTerminalInPart01Left{
        grid-column: 1;
        grid-row: 5;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .iPadTerminalInPart01Right{
        top: 0;
        grid-column: 1;
        grid-row: 4;
        display: flex;
        justify-content: center;
    }

    .iPadTerminalInPart03{
        grid-template-columns: auto;
    }
    .iPadTerminalInPart03Right{
        justify-content: center;
    }


    .iPadTerminalInPart02Right>.iPadTerminalTitleH2{
        font-size: 22px;
    }

    .iPadTerminalIn>.StandartIn{
        padding-right: 0;
        padding-left: 0;
    }
    .iPadTerminalInPart02,.iPadTerminalInPart03{
        padding-top: 0;
    }
    .iPadTerminalInPart02Right>.iPadTerminalTitleH2, .iPadTerminalInPart03Left>.iPadTerminalTitleH2{
        padding-top: 20px;
    }
}
@media only screen and (max-width: 430px) {
    .iPadTerminalInPart01Right{
        height: auto;
    }
    .iPadTerminalInPart02Left>img,.iPadTerminalInPart03Right>img{
        width: 100%;
        object-fit: contain;
    }
}

@media only screen and (max-width: 400px) {
    .iPadTerminalInPart01LeftStore{
        line-height: 40px;
        padding-left: 2px;
        padding-right: 20px;
    }
    .iPadTerminalInPart01LeftText{
        line-height: 30px;
        padding-left: 2px;
        padding-bottom: 10px;
    }
}


@media only screen and (max-width: 330px) {
    .iPadTerminalInTitleGridButtons>a{
        margin-left: 2px;
    }
}

.iPadTerminal{
    font-family: 'Geometria', sans-serif;
}

.iPadTerminalTitle{
    font-weight: 700;
    font-size: 30px;
    color: #413422;
    padding-top: 8px;
}

.TerminalIn{
    font-family: Geometria, sans-serif;
}

.iPadTerminalInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGCircles{
    margin-bottom: 30px;

}

.iPadTerminalInPart01{
    display: grid;
    grid-template-columns: 3fr 1fr 500px;
    padding-top: 30px;
}

@media only screen and (max-width: 1050px) {
    .iPadTerminal>.iPadTerminalIn>.iPadTerminalInPart01>.iPadTerminalInPart01Right{
        grid-row: 3;
    }

}

@media only screen and (max-width: 615px) {
    .iPadTerminal>.iPadTerminalIn>.iPadTerminalInPart03>.iPadTerminalInPart03Right>img{
        width: 100%;
        object-fit: contain;
        left: 0;
    }
}

@media only screen and (max-width: 900px) {
    .iPadTerminalInPart01{
        grid-template-columns: auto;
        height: auto;
    }
    .iPadTerminalInPart01{
        grid-template-columns: auto;
        height: auto;
    }
    .iPadTerminalTitle{
        grid-column: 1;
        grid-row: 1 ;
    }

    .iPadTerminalInPart01RightMainImage{
        display: none;
    }

    .iPadTerminalInPart01Left{
        grid-column: 1;
        grid-row: 5;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .iPadTerminal>.iPadTerminalIn>.iPadTerminalInPart01>.iPadTerminalInPart01Right{
        top: 0;
        grid-column: 1;
        grid-row: 4;
        display: flex;
        justify-content: center;
    }
}

.iPadTerminalInPart02{
    height: auto;}


.iPadTerminalInPart02Left>img,.iPadTerminalInPart03Right>img{
    margin: auto;
}

@media only screen and (max-width: 615px) {
    .iPadTerminalInPart03Right{
        width: 100%;
    }
    .iPadTerminalInPart02Left{
        width: 100%;
    }
    .iPadTerminalInPart02Left>img,.iPadTerminalInPart03Right>img{
        width: 99%;
    }
}


.iPadTerminalPadInPart01RightMainImage{
    position: relative;
    top: 60px;
    left: -16px;
    width: 560px;
}

.iPadTerminalPadInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGImage{
    position: absolute;
    top: 130px;
    left: 38px;
}

.iPadTerminalInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGCircles{
    position: absolute;
    top: 1px;
    left: 238px;
}

@media only screen and (max-width: 900px) {
    .iPadTerminal>.iPadTerminalIn>.iPadTerminalInPart01>.iPadTerminalInPart01Right{
        grid-column: 1/span 3;
    }

    .iPadTerminalPadInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGImage{
        position: static;
        left: 0;
    }
    .iPadTerminalInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGCircles{
        position: static;
    }
}

@media only screen and (max-width: 700px) {
    .TerminalComponent3IMGImage>img {
        width: 100%;
    }
}
@media only screen and (max-width: 615px) {
    .iPadTerminalInPart02Left{
        width: 100%;
    }
}

.iPadTerminalPadInPart01RightMainImage{
    position: relative;
    left: -70px;
}

@media only screen and (max-width: 1300px) {
    .iPadTerminalInPart02Left{
        position: relative;
    }
    .iPadTerminalInPart02{
        padding-left: 0;
        grid-template-columns: 4fr 3fr;
        position: relative;
    }
    .iPadTerminalInPart02>.iPadTerminalInPart02Left>img{
        position: absolute;
    }
    .iPadTerminalInPart02Left:before{
        content: '';
        height: 100%;
        width: 500px;
        position: absolute;
        top: 0;
        left: -525px;
        z-index: 10;
        background-color: #edf1f1;
    }
    .iPadTerminalInPart02:before{
        content: '';
        height: 100%;
        width: 25px;
        position: absolute;
        top: 0;
        left: -25px;
        z-index: 10;
        background-color: #edf1f1;
    }
    .iPadTerminalInPart03{
        display: grid;
        grid-template-columns: 400px auto;
        grid-column-gap: 50px;
        grid-template-rows: auto;
    }
    .iPadTerminalInPart03>.iPadTerminalInPart03Right{overflow: hidden;}


    .iPadTerminalInPart03>.iPadTerminalInPart03Right>img{
        position: absolute;
        left: -3px;
        overflow: hidden;

    }
}


@media only screen and (max-width: 615px) {
    .iPadTerminalInPart02,.iPadTerminalInPart03{
        grid-template-columns: auto;
    }
    .iPadTerminalInPart02>.iPadTerminalInPart02Left>img,.iPadTerminalInPart03>.iPadTerminalInPart03Right>img{
        position: relative;
    }
    .iPadTerminalInPart03{
        display: flex;
        flex-direction: column;
    }
    .iPadTerminalInPart03Right{
        order: 1;
    }
    .iPadTerminalInPart03Left{
        order: 2;
    }
    .iPadTerminalInPart03:after{
        width: 0;
    }

}
@media only screen and (max-width: 615px) {
    .iPadTerminal>.StandartIn{
        padding-left: 0;
        padding-right: 0;

    }
}

.iPadTerminalIn>.StandartIn{
    padding-right: 0;
}

.iPadTerminalInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGCircles{
    left: 210px;
}

@media only screen and (max-width: 1050px) {
    .iPadTerminalInPart01LeftTextMain{
        padding-right: 30px;
    }

}
@media only screen and (max-width: 900px) {
    .iPadTerminalInPart01Right{
        left: unset;
        grid-column: 1/span 3;
    }    .iPadTerminalInPart01Left{
             left: unset;
             grid-column: 1/span 3;
         }
    .TerminalComponent3IMGImage{
        width: auto;
    }
    .iPadTerminalInPart01Left{
        margin-top: 0;
    }
    .iPadTerminalInPart01LeftTextMain{
        padding-top: 0;
    }
}

@media only screen and (max-width: 700px) {
    .iPadTerminal>.iPadTerminalIn>.iPadTerminalInPart01>.iPadTerminalInPart01Right,.iPadTerminal>.iPadTerminalIn>.iPadTerminalInPart01>.iPadTerminalInPart01Left{
        grid-column: 1;
    }
    .iPadTerminalInPart01{
        grid-template-rows: auto;
    }

    .iPadTerminalInPart01Right{
        grid-column: 1;
    }    .iPadTerminalInPart01Left{
             grid-column: 1;
         }
         .iPadTerminalInTitleGridButtons{
             margin-top: 20px;
             margin-bottom: 20px;
         }
}


.iPadTerminalInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGImage>img{
    position: relative;
    left: -30px;
    top: 2px;

}

@media only screen and (max-width: 900px) {
    .iPadTerminalInPart01Right>.TerminalComponent3IMG>.TerminalComponent3IMGImage>img{
        left: 0;
    }
}

