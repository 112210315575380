

.PageMainPart{
    font-family: GothamPro, sans-serif;
    display: grid;
    grid-template-columns: 348px auto;
}


@media only screen and (max-width: 1000px) {
    .PageMainPart{
        display: block;
    }
}

.PageMainPartFirst{
    display: grid;
    grid-template-columns: 8fr 10fr;
    grid-column-gap: 120px;
    width: 100%;
}

.PageMainPartFirstTextTop{
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: 80px auto;
    grid-column-gap: 35px;
}
.PageMainPartFirstTextTopText{
    font-family: GothamPro, sans-serif;
    font-weight: 700;
    font-size: 48px;
    text-align: left;
    color: black;
    padding-top: 50px;
}

.PageMainPartFirstTextTopImg{
    display: flex;
    align-items: center;
    padding-right: 29px;
}

.PageMainPartFirstTextBrowsers{
    font-family: GothamPro,sans-serif;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    color: black;
    display: grid;
    grid-template-columns: 80px auto;
    grid-column-gap: 35px;
}

.PageMainPartFirstTextBrowsersImg{
    grid-row: 1/span 2;
}

.PageMainPartFirstTextBrowsersTextSystems{
    display: flex;
    grid-column: 2;
}

.PageMainPartFirstTextBrowsersTextSystemsOS{
    display: flex;
    justify-content: space-around;
    border: 1px solid #8d8da3;
    border-radius: 24px;
    width: 231px;
    padding: 9px 10px 3px 10px;
    margin-top: 15px;
}
.PageMainPartFirstTextBrowsersTextSystemsBrowsers{
    display: flex;
    justify-content: space-around;
    border: 1px solid #8d8da3;
    border-radius: 24px;
    width: 251px;
    padding: 7px 10px 4px 10px;
    margin-top: 15px;
    margin-left: 20px;
}

.PageMainPartStandartElementTextTitle{
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    color: black;
}

.IndexBottomButton{
    margin-top: 40px;
}

.IndexBottomButton>a{
    text-decoration: none;
}

.IndexBottomButton>a>.ButtonWhite>b{
    padding-left: 5px;
}

.PageMainPart>.StandartTitle{
    padding-top: 80px;
}

/*.PageMainPart02{*/
/*    padding-bottom: 100px;*/
/*}*/

.PageMainPart04IMG{
    margin-top: 150px;
}

.PageMainPart02IMGText{
    font-family: GothamPro,sans-serif;
    font-size: 24px;
    line-height: 0.91;
    text-align: left;
    color: #ffffff;
    transform: rotate(15deg);
    position: absolute;
    top: 520px;
    left: 170px;
    width: 300px;
}
.PageMainPart03IMGText{
    font-family: GothamPro,sans-serif;
    font-size: 24px;
    line-height: 0.91;
    text-align: left;
    color: #ffffff;
    transform: rotate(-9deg);
    top: auto;
    position: absolute;
    bottom: 60px;
    left: 250px;
}

.PageAdvantage>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>div>.MainMenuGlobalLinkActive{
    color: black;
}

.PageAdvantage>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>div>.MainMenuGlobalLinkActive>.MainMenuLinkUnderline{
    background-color: black;
}

.PageAdvantage>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLink>.MobiusMenuHrefIn>.MainMenuLinkUnderline{
    background-color: #1466b4;
}
.PageAdvantage>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLinkActive>.MobiusMenuHrefIn>.MainMenuLinkUnderline{
    background-color: black;
}

.PageAdvantage>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLink{
    color: #1466b4;
}

.PageAdvantage>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLinkActive{
    color: black;
}

@media only screen and (max-width: 1410px) {
    .PageMainPart03IMGText{
        /*bottom: -260px;*/
    }
}

.PageMainIndexWhat{
    padding-top: 100px;
    padding-bottom: 10px;
    position: relative;
    z-index: 3;
}

.PageMainPart02IMG{
    position: relative;
    top: -90px;
}

.PageMainPart02IMG>img{
    /*position: absolute;*/
}
.PageMainPart05IMG{
    position: relative;
    height: 766px;
}
.PageMainPart05IMG01{
    position: relative;
    left: -150px;
}

.PageMainPart05IMG02{
    position: absolute;
    left: -50px;
    top: 100px;
}

.PageMainPart03IMG{
    position: relative;
    /*top: -60px;*/
    /*margin-bottom: 50px;*/
}

.PageMainPart03IMG>img{
    /*position: absolute;*/
}

.PageMainPart02IMG01{
    object-fit: contain;
    box-shadow: 2px -2px 79px 0 rgba(0, 0, 0, 0.45);
}

.PageMainPart02IMG02{
    top: 30px;
    left: 80px;
}
.PageMainPart03IMG02{
    top: 50px;
    left: 50px;
}

.PageMainPart02Text>.StandartH2{
    padding-top: 70px;
    padding-bottom: 10px;
}

.PageMainPartStandartElement{
    display: grid;
    grid-template-columns: 80px auto;
    grid-column-gap: 35px;
    padding-top: 43px;
}

.PageMainPartStandartElementTextText{
    font-size: 24px;
    text-align: left;
    color: black;
}

.PageMainPart02{
    padding-left: 163px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.PageMainPart03{
    padding-right: 163px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /*padding-bottom: 180px;*/
}

.PageMainPart02Element{
    display: grid;
    grid-template-columns: 80px auto;
    grid-column-gap: 15px;
    padding-top: 35px;
}

.PageMainButtonBottomOut{
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
}

.PageMainButtonBottomOut>a{
    text-decoration: none;
}

.PageMainButtonBottom{
    padding: 6px 30px 6px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    border-radius: 34px;
    box-shadow: 3px -3px 4px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px #150c15;
    background-color: #ffffff;
    font-size: 30px;
    text-align: center;
}
.PageMainButtonBottom:hover{
    background-color: #474187;
    color: white;
}

.PageMainButtonBottom>b{
    padding-left: 5px;
}

.DemoInfoAfterRegistrationIn>div>a>.ButtonWhite{
    height: 35px;
}

.PageMainPartFirstTextFirstImg{
    display: none;
}


@media only screen and (max-width: 1200px) {
    .PageMainPartFirstTextBrowsersTextSystems{
        flex-wrap: wrap;
        justify-content: center;
        grid-column: 1/span 2;
    }
    .PageMainPartFirstTextBrowsersTextSystemsOS{
        margin-left: 30px;
    }
    .PageMainPartFirstTextBrowsersTextSystemsOS{
        height: 36px;
    }
    .PageMainPartFirstTextTopText{
        font-size: 30px;
        font-weight: 400;
    }
    .PageMainPartFirstTextBrowsersImg{
        padding-right: 35px;
    }
    .PageMainPartFirstTextBrowsersImg{
        grid-row: 1;
    }
    .PageMainPart02{
        padding-left: 10px;
    }
}

@media only screen and (max-width: 900px) {
    .PageMainPartFirstTextFirstImg>img{
        max-height: 400px;
    }
    .PageMainPartFirstTextBrowsersTextSystemsOS,.PageMainPartFirstTextBrowsersTextSystemsBrowsers{
        margin-left: 0;
    }
    .PageMainPartFirst{
        grid-template-columns: 3fr 10fr;
    }
    .PageMainPartFirstImg>img{
        display: none;
    }

    .PageMainPartFirstTextFirstImg{
        display: block;
    }
    .PageMainPartFirst{
        display: flex;
        flex-wrap: wrap;
    }
    .PageMainPartFirstText{
        order: 1;
    }
    .PageMainPartFirstImg{
        order: 2;
    }
    .PageMainPartFirstTextBrowsersTextSystems{
        justify-content: space-around;
    }
}
@media only screen and (max-width: 500px) {
    .PageMainPartStandartElementTextTitle{
        font-size: 20px;

    }
    .PageMainPartStandartElementTextText{
        font-size: 20px;
    }
}

@media only screen and (max-width: 400px) {
    .PageMainPartStandartElement{
        grid-template-columns: 70px auto;
        grid-column-gap: 10px;
    }
    .PageMainPartStandartElementTextTitle{
        font-size: 17px;

    }
    .PageMainPartStandartElementTextText{
        font-size: 17px;
    }
    .PageMainPartFirstTextTopText{
        font-size: 20px;
    }
    .PageMainPartFirstTextBrowsersTextTitle{
        font-size: 17px;
    }
    .PageMainPartFirstOut{
        padding-right: 10px;
        padding-left: 10px;
    }
}

.PageMainPart02{
    grid-column-gap: 30px;
}

.PageMainPartFirstOut{
    overflow-x: hidden;
}

@media only screen and (max-width: 1200px) {
    .PageMainIndexWhat{
        font-size: 36px;
        padding-top: 30px;
    }
}

@media only screen and (max-width: 1300px) {
    .PageMainPart02IMG{
        right: 100px;
        z-index: -1;
    }
    .PageMainPart04IMG{
        right: 100px;
        z-index: -1;
    }
}

@media only screen and (max-width: 1100px) {
    .PageMainPart02{
        padding-left: 0;
    }
}
@media only screen and (max-width: 1000px) {
    .PageMainPart02{
        display: grid;
        grid-template-columns: auto;
    }
    .PageMainPart02IMG>img{
        position: relative;
    }
    .PageMainPart02IMG{
        grid-row: 1;
        top: -110px;
        height: 450px;
        left: -10px;
        /*display: flex;*/
        /*justify-content: center;*/
    }
    .PageMainPart04IMG{
        /*grid-row: 1;*/
        margin-top: 50px;
        /*top: -100px;*/
        /*height: 450px;*/
        /*left: -10px;*/
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 800px) {
    .PageMainPart02IMG{
        display: none;
    }
    .PageMainPart04IMG{
        display: none;
    }
    }

@media only screen and (max-width: 600px) {
    .PageMainPart02IMGText{
        display: none;
    }
    .PageMainPart02IMG{
        position: static;
        width: 100%;
        height: auto;
    }
    .PageMainPart04IMG{
        position: static;
        width: 100%;
        height: auto;
    }
    .PageMainPart02IMG>img{
        position: static;
        width: 100%;
    }
    .PageMainPart02Text>.StandartH2{
        font-size: 24px;
        padding-top: 5px;
    }
    .PageMainPart02Text>.PageMainPart02Element>.PageMainPartStandartElementText>.PageMainPartStandartElementTextText{
        font-size: 17px;
    }
    .PageMainPart03IMG{
        display: none;
    }
}

@media only screen and (max-width: 450px) {
    .PageMainPartStandartElementTextText{
        font-size: 15px;
    }
}

.PageMainPart03{
    grid-column-gap: 40px;
}

@media only screen and (max-width: 1300px) {
    .PageMainPart03{
        padding-right: 30px;
    }
    .PageMainPart03IMGText{
        top: 560px;
    }
}

@media only screen and (max-width: 1200px) {
    .PageMainPart03{
        padding-right: 0;
    }
    .PageMainPart03IMGText{
        display: none;
    }
    .PageMainPart03IMG{
        left: -50px;
    }
    .PageMainPart03IMG>img{
        width: 100%;
    }
}
@media only screen and (max-width: 1100px) {
    .PageMainPart03{
        padding-bottom: 0;
    }
    .PageMainPart03IMG{
        position: relative;
        top: -50px;
    }
}

@media only screen and (max-width: 800px) {
    .PageMainPartFirstOut>.PageMainPart02{
        padding-bottom: 10px;
    }
    .PageMainPartFirstOut>.PageMainPart03{
        display: block;
    }
    .PageMainPart03IMG>img{
        position: relative;
        height: 400px;
    }
    .PageMainPart03IMG{
        top: 0;
        height: auto;
    }
}
@media only screen and (max-width: 650px) {
    .PageMainPart04IMG{
        width: 100%;
    }
    .PageMainPart04IMG>img{
        width: 100%;
        height: auto;
    }
    .PageMainPartStandartElementTextTitle,.PageMainPartStandartElementTextText{
        font-size: 13px;
    }
}

.PageMainPart05IMG{
    width: 100%;
}
.PageMainPart05IMG02,.PageMainPart05IMG01{
    width: 100%;
    left: -30px;
}

.PageMainPart05IMG01{
    width: 120%;
    left: -70px;
}

@media only screen and (max-width: 1000px) {
    .PageMainPartFirstEnd{
        display: flex;
        flex-wrap: wrap;
    }
    .PageMainPart05IMG{
        max-height: 400px;
    }
    .PageMainPart05IMG01{
        width: 100%;
        left: 0;
        max-height: 400px;

    }
    .PageMainPart05IMG02{
        width: 100%;
        left: 0;
        max-height: 400px;
    }
    .PageMainPart05IMG{
        display: none;
    }
}

@media only screen and (max-width: 700px) {
    .PageMainButtonBottom>img{
        display: none;
    }
    .PageMainButtonBottom{
        font-size: 15px;
    }
}

@media only screen and (max-width: 350px) {
    .PageMainButtonBottom{
        padding-left: 10px;
        padding-right: 10px;
    }
}

.PageMainButtonBottom>img{
    padding-left: 15px;
}

.PageMainPartBottomButton{
    margin-top: 30px;
    margin-bottom: 30px;
}

.PageMainPartBottomButton>a>.ButtonWhite{
    text-decoration: underline;
}
.PageMainPartBottomButton>a>.ButtonWhite>b{
    padding-right: 10px;
}

.PageMainPartBottomButton>a>.ButtonWhite:hover{
    text-decoration: underline;
    color: white;
}

.ButtonWhiteImgWhite{
    display: none;
}

.PageMainPartBottomButton>a>.ButtonWhite:hover>.ButtonWhiteImgWhite{
    display: block;
}

.PageMainPartBottomButton>a>.ButtonWhite:hover>.ButtonWhiteImg{
    display: none;
}

.PageMainJavaScript>b{
    padding-right: 10px;
}