.TopVidgets{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 50px;
}

.TopVidgets>div{
    margin-bottom: 43px;

}


.TopVidgets01{
    display: grid;
    grid-template-columns: 55px 250px;
    grid-column-gap: 21px;
    justify-content: center;
}

.TopVidgets01Img{
    position: relative;
    /*top: 25px;*/
}

.TopVidgets01>.TopVidgets01Img>.TopVidgets01ImgHover{display: none;}
.TopVidgets01:hover>.TopVidgets01Img>.TopVidgets01ImgHover{display: block;}
.TopVidgets01:hover>.TopVidgets01Img>.TopVidgets01ImgNormal{display: none;}



.TopVidgets02Element{
    display: flex;
    padding-bottom: 9px;
    align-items: center;
    font-size: 22px;
    text-align: left;
    color: #151e27;
    color: #1466b4;
}

.TopVidgets02Element>img{
    padding-right: 11px;
    width: 45px;
}

.TopVidgets02Element01>.TopVidgets02Element01Hover{display: none;}
.TopVidgets02Element01:hover>.TopVidgets02Element01Hover{display: block;}
.TopVidgets02Element01:hover>.TopVidgets02Element01Normal{display: none;}

.TopVidgets02Element02>.TopVidgets02Element02Hover{display: none;}
.TopVidgets02Element02:hover>.TopVidgets02Element02Hover{display: block;}
.TopVidgets02Element02:hover>.TopVidgets02Element02Normal{display: none;}

.TopVidgets02Element03>.TopVidgets02Element03Hover{display: none;}
.TopVidgets02Element03:hover>.TopVidgets02Element03Hover{display: block;}
.TopVidgets02Element03:hover>.TopVidgets02Element03Normal{display: none;}

.TopVidgets01Text{
    grid-column: 2;
    grid-row: 1;
}

.TopVidgets01TextTitle{
    font-size: 24px;
}

.TopVidgets01Text>a,.TopVidgets01Text>a:visited{
    color: #1466b6
}

.TopVidgets01TextText{
    font-size: 18px;
    color: #333333;
}

.TopVidgets02{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.TopVidgets03{
    border: 2px solid #0c3f70;
    transform: skewX(-20deg);
    display: flex;
    padding: 24px 30px 24px 30px;
    height: 80px ;
    margin-left: 40px;
}

.TopVidgets03Text,.TopVidgets03>img{
    transform: skewX(20deg);
}

.TopVidgets03>img{
    margin-right: 27px;
    margin-top: 3px;
}

.TopVidgets03Elements{
    display: grid;
    grid-template-columns: auto auto;
    padding-top: 12px;
}

.TopVidgets03Title{
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    color: #151e27;
}

.TopVidgets03Elements{
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 30px;
    grid-row-gap: 5px;
}

.TopVidgets03Elements>div{
    font-size: 20px;
    color: #151e27;
}

@media only screen and (max-width: 650px) {
    .TopVidgets03{
        border: none;
        transform: unset;
        padding-top: 0;
        margin: 0;

    }
    .TopVidgets{
        margin-bottom: 10px;
    }
    .TopVidgets>div{
        margin-bottom: 10px;
    }
    .TopVidgets03{
        height: auto;
    }
    .TopVidgets03>div,.TopVidgets03>img{
        transform: unset;

    }
    .TopVidgets03Elements{
        display: flex;
        flex-wrap: wrap;
    }
    .TopVidgets03Elements>div{
        padding-right: 10px;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 500px) {
    .TopVidgets01{
        grid-template-columns: 55px auto;
    }
    .TopVidgets02>a>div{
        font-size: 15px;
    }
    .TopVidgets01TextTitle{
        font-size: 20px;
    }
    .TopVidgets01TextText{
        font-size: 15px;
        padding-bottom: 10px;
    }
}