.MainMenuGlobal{
    margin: auto;
    /*position: relative;*/
    height: 180px;
    max-width: 1058px;
}

.PageCompany>header{
    /*position: relative;*/
    z-index: 6;
}

.AllBlack{
    background-color : #000;
    height : 100%;
    left : 0;
    opacity : 0.50;
    position : fixed;
    top : 0;
    width : 100%;
    z-index : 100;
}

.MainMenuGlobalFirstPartSVG,.MainMenuGlobalFirstPartSVGHover{
    padding-right: 6px;
}

.MainMenuGlobalFirstPartSVGHover{
    display: none;
}

.MainMenuGlobalFirstPart:hover>.MainMenuGlobalFirstPartIn>.MainMenuGlobalFirstPartSVGHover{
    display: block;
}
.MainMenuGlobalFirstPart:hover>.MainMenuGlobalFirstPartIn{
    color: #20af1b;
}
.MainMenuGlobalFirstPart:hover>.MainMenuGlobalFirstPartIn>.MainMenuGlobalFirstPartSVG{
    display: none;
}

@keyframes forMainMenuGlobalFirstPartOut {
    from {
        visibility: hidden;
        top: -300px;
    }

    to {
        top: 0;
        visibility: visible;
    }
}


.MainMenuGlobalFirstPartOut{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    cursor: pointer;
    /*position: relative;*/
    z-index: 20;
    /*animation-name: forMainMenuGlobalFirstPartOut;*/
    /*visibility: hidden;*/
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-delay: 200ms;
    top: 0;
    visibility: visible;
}

.MainMenuGlobalFirstPart{
    color: #ebf0f6;
    font-size: 13px;
    padding-left: 2px;
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    top: -7px;
}

.MainMenuGlobalFirstPartBackground{
    display: flex;
    justify-content: center;
}

.MainMenuGlobalFirstPartBackground>.MainMenuGlobalFirstPartBackgroundHover{
    display: none;
}
.MainMenuGlobalFirstPartBackground:hover>.MainMenuGlobalFirstPartBackgroundHover{
    display: block;
}
.MainMenuGlobalFirstPartBackground:hover>.MainMenuGlobalFirstPartBackgroundStatic{
    display: none;
}

.MainMenuGlobalFirstPartBackground>.MainMenuGlobalFirstPartIn>.MainMenuGlobalFirstPartSVGHover{
    display: none;
}
.MainMenuGlobalFirstPartBackground:hover>.MainMenuGlobalFirstPartIn>.MainMenuGlobalFirstPartSVGHover{
    display: block;
}
.MainMenuGlobalFirstPartBackground:hover>.MainMenuGlobalFirstPartIn>.MainMenuGlobalFirstPartSVG{
    display: none;
}

.MainMenuGlobalFirstPartBackground:hover>.MainMenuGlobalFirstPartIn{
    color: white;
}

.MainMenuLink{
    text-decoration: none;
}

/*.MainMenuLink:nth-of-type(4){*/
/*    display: none;*/
/*}*/

.MainMenuGlobalFirstPartIn{
    position: absolute;
    text-decoration: underline;
    color: #1466b6;
    top: 14px;
    display: flex;
    align-items: center;
    margin: auto;
}

.MainMenuGlobalFirstPart:hover{
    color: red;
}
.MainMenuGlobalFirstPart:active{
    color: #bfcde1;
}

.MainMenuGlobalSecondPart>a,.MainMenuGlobalSecondPart>a:visited{
    text-decoration: none;
    color: #1466b4;
    position: relative;
}

.MainMenuGlobalHrefs>div>a,.MainMenuGlobalHrefs>div>a:visited{
    color: #1466b4;
}

.MainMenuGlobalHrefs>div>a:hover{
    color: #81af1b;
}

.EnterPersonalClient{
    top: 0;
    width: 375px;
    height: 329px;
    position: fixed;
    left: 50%;
    margin-left: -140px;
    background-color: white;
    z-index: 200;
    border: 1px black solid;
}


@media only screen and (max-width: 550px) {
    .EnterPersonalClient{
        left: 0;
        margin-left: 0;
    }
}


.EnterPersonalClientIn{
    padding: 30px 30px 34px 30px;
    position: relative;
    z-index: 200;
}

@media only screen and (max-width: 375px) {
    .EnterPersonalClient{
        width: 100%;
    }
    .EnterPersonalClientIn{
        padding-left: 5px;
        padding-right: 5px;
    }
}

.LoginToCabinetForm>.PersonalPageInput01,.DemoRegistrationForm>.PersonalPageInput{
    padding-top: 10px;
    padding-bottom: 2px;
    margin-top: 10px;
}

.LoginToCabinetForm>.PersonalPageInput02{
    padding-bottom: 6px;
}

@keyframes forMobiusHeaderLogo {
    from {
        visibility: hidden;
        left: -300px;
    }

    to {
        left: 0;
        visibility: visible;
    }
}
.MobiusHeaderLogo{
    display: inline-flex;
    position: relative;
    z-index: 5;
    animation-name: forMobiusHeaderLogo;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-delay: 200ms;
    left: 0;
    visibility: hidden;
}


.MainMenuGlobalSecondPart{
    display: grid;
    grid-template-columns: auto minmax(308px, 500px) auto ;
    justify-content: space-between;
    align-items: center;
    margin-top: 1px;
}

@media only screen and (max-width: 1100px) {
    .MainMenuGlobalSecondPart{
        padding-left: 30px;
    }
}
@media only screen and (max-width: 696px) {
    .MainMenuGlobalSecondPart{
        margin-top: 15px;
    }
}

.MainMenuGlobalHrefs{
    display: flex;
    grid-column: 2;
    justify-content: space-between;
    font-size: 14px;
    /*position: relative;*/
    z-index: 10;
    /*animation-name: forMainMenuGlobalFirstPartOut;*/
    /*visibility: hidden;*/
    animation-duration: 400ms;
    animation-fill-mode: forwards;
    animation-delay: 200ms;
    visibility: visible;
    margin-right: 50px;
}




.MainMenuGlobalHrefsBorder{
    position: absolute;
    height: 1px;
    width: 0;
    background-color: white;
    z-index: 500;
}

.MainMenuGlobalMobius7{
    position: relative;
    z-index: 5;
}


@keyframes forMainMenuGlobalHrefMobius {
    from {
        visibility: hidden;
        left: 300px;
    }

    to {
        left: 0;
        visibility: visible;
    }
}
.MainMenuGlobalSecondPart>.MainMenuGlobalHrefMobius {
    right: 40px;
}
.MainMenuGlobalSecondPart>a>.MainMenuGlobalHrefMobius{
    /*animation-name: forMainMenuGlobalHrefMobius;*/
    /*visibility: hidden;*/
    text-decoration: none;
    display: flex;
    color: black;
    z-index: 5;
    animation-duration: 400ms;
    animation-fill-mode: forwards;
    animation-delay: 200ms;
    position: relative;
    visibility: visible;
    margin-top: 5px;
}



.MainMenuGlobalSecondPart>a>.MainMenuGlobalHrefMobius:hover{
    color: #20af1b;
}



.MobiusHeaderLogoText{
    font-weight: normal;
    font-size: 21px;
    line-height: 23px;
    color: black;
    padding-left: 12px;
    padding-top: 3px;
}

.MainMenuGlobalFirstPartText{
    padding-left: 5px;
}

.EnterPersonalClientTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: lighter;
    font-size: 30px;
    padding-bottom: 20px;
}

.EnterPersonalClientTitle>div>img{
    cursor: pointer;
}

.EnterPersonalClientIn{}

.EnterPersonalClientIn>.ButtonWhiteOut{
    display: flex;
}
.EnterPersonalClientIn>.ButtonWhiteOut>.ButtonWhite{
    font-size: 18px;
    padding: 10px 37px 10px 37px;
}

.LoginToCabinetForm>.PersonalPageInput,.DemoRegistrationForm>.PersonalPageInput{

    width: 280px;
    margin-right: 60px;
}

.LoginToCabinetForm>.PersonalPageInput>label>input,.DemoRegistrationForm>.PersonalPageInput>label>input{
    width: 315px;
}

.LoginToCabinetForm>.PersonalPageInput,.DemoRegistrationForm>.PersonalPageInput{
    position: relative;
    z-index: 5;
    width: 320px;
    margin-bottom: 30px;
}

.PersonalPageInput>label{
    position: relative;
    z-index: 5;
}

.PersonalPageInput>label>input:hover {
    background-color: #eeeeee;
}

.PersonalPageInput>label>input:focus{
    outline: 0;
    outline-offset: 0;
    background: none;
}

.PersonalPageInput>label>input:focus + span {
    transform: translateY(-14px) scale(.75);
    opacity: 1;
}

.PersonalPageInput>label>input:not(:placeholder-shown) + span {
    transform: translateY(-14px) scale(.75);
    color: #f5b95b;
    opacity: 1;
}
.LoginToCabinetForm>.PersonalPageInput>label,.DemoRegistrationForm>.PersonalPageInput>label{
    position: relative;
    z-index: 5;
}

.LoginToCabinetForm>.PersonalPageInput>label>input:hover,.DemoRegistrationForm>.PersonalPageInput>label>input:hover {
    background-color: #eeeeee;
}

.LoginToCabinetForm>.PersonalPageInput>label>input:focus,.DemoRegistrationForm>.PersonalPageInput>label>input:focus{
    outline: 0;
    outline-offset: 0;
    background: none;
}

.LoginToCabinetForm>.PersonalPageInput>label>input:focus + span,.DemoRegistrationForm>.PersonalPageInput>label>input:focus + span {
    transform: translateY(-14px) scale(.75);
    opacity: 1;
}

.LoginToCabinetForm>.PersonalPageInput>label>input:not(:placeholder-shown) + span,.DemoRegistrationForm>.PersonalPageInput>label>input:not(:placeholder-shown) + span {
    transform: translateY(-14px) scale(.75);
    color: #f5b95b;
    opacity: 1;
}

.LoginToCabinetForm>.PersonalPageInput02>label>input, .LoginToCabinetForm>.PersonalPageInput01>label>input,.DemoRegistrationForm>.PersonalPageInput>label>input{
    color: black;
}

.LoginToCabinetForm>.PersonalPageInput>label>.TextPlaceHolder,.DemoRegistrationForm>.PersonalPageInput>label>.TextPlaceHolder {
    width: 100%;
    position: absolute;
    top: 8px;
    left: 0;
    font-size: 17px;
    color: #190a0d;
    opacity: 0.5;
    font-weight: 400;
    transform-origin: 0 0;
    transition: all .2s ease;
}


.LoginToCabinetForm>.PersonalPageInput>label>input,.DemoRegistrationForm>.PersonalPageInput>label>input {
    border: 0;
    padding: 5px 0;
    font-size: 20px;
    font-weight: 400;
    border-bottom: 1px solid rgba(25, 10, 13, 0.3);
    background: none;
    transition: all .15s ease;
}

.PersonalPageInputError{
    position: relative;
    z-index: 5;
}

.LoginToCabinetForm>.PersonalPageInputError>label>input,.DemoRegistrationForm>.PersonalPageInputError>label>input{
    border-bottom: 2px solid red;
    color: red;
}
.SignInform>.PersonalPageInputError>label>.TextPlaceHolder{
   color:  red;
}
.EnterPersonalClientIn>.LoginToCabinetForm>.PersonalPageInputError>label>.TextPlaceHolder{
   color:  #ff1010;
    font-size: 11px;
}

.PersonalPageInputErrorText{
    display: none;
}

.PersonalPageInputError>label>.PersonalPageInputErrorText{
    display: flex;
    position: absolute;
    top: -19px;
    left: 318px;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: white;
    background-image: url("../../static/img/Header/errorBack.png");
    background-size: 305px 46px;
    width: 305px;
    height: 46px;
}

@media only screen and (max-width: 1100px) {
    .PersonalPageInputError>label>.PersonalPageInputErrorText{
        position: absolute;
        display: block;
        top: -25px;
        left: 100px;
        background-image: url("../../static/img/Header/ErrorBackPhone.svg");
        background-size: 218px 41px;
        width: 218px;
        height: 41px;
        text-align: center;
        line-height: 35px;
    }

}

.MainMenuGlobalMobius7Demo{
    width: 212px;
    height: 48px;
    background-image: url("../../static/img/Header/demoBack.svg");
    background-size: 212px 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.MainMenuGlobalMobius7DemoText{
    color: #d6dde4;
    font-size: 16px;
    padding-left: 15px;
    padding-right: 25px;
}

.DemoRegistration{
    width: 379px;
    height: 252px;
    position: fixed;
    left: 50%;
    margin-left: -140px;
    background-color: white;
    z-index: 200;
    top: 0;
    border: 1px black solid;
}

.DemoRegistrationIn{
    padding: 30px 31px 0 31px;
}

.DemoInfoAfterRegistration{
    width: 667px;
    height: 314px;
    position: fixed;
    left: 50%;
    top: 30%;
    margin-left: -330px;
    background-color: white;
    z-index: 200;
    border: 1px black solid;
}

.DemoInfoAfterRegistrationIn{
    padding: 30px 31px 0 31px;
}

.DemoInfoAfterRegistrationMain{
    display: grid;
    grid-template-columns: 90px auto;
    grid-column-gap: 30px;
    margin-top: 10px;
    margin-bottom: 43px;
}

.ButtonWhiteOut{
    display: flex;
    align-items: center;
    justify-content: left;
}

/*.DemoInfoAfterRegistrationIn>.ButtonWhiteOut>a>.ButtonWhite{*/
/*    font-size: 18px;*/
/*}*/

.DemoInfoAfterRegistrationBottomText{
    padding-left: 40px;
}

.DemoInfoAfterRegistrationMainInfoTitle{
    font-size: 19px;
    padding-bottom: 10px;
}
.DemoInfoAfterRegistrationMainInfoElement{
    font-size: 19px;
    padding-top: 9px;
    display: grid;
    grid-template-columns: 120px auto;
}

.DemoInfoAfterRegistrationMainInfoElement{
    font-size: 19px;
}

@media only screen and (max-width: 1000px) {
.MobiusHeaderLogoText{
    display: none;
}
    .MainMenuGlobalHrefs>a{
        margin-bottom: 15px;
    }
    .MainMenuGlobalHrefs{
        padding-left: 10px;
        display: flex;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 700px) {
    .MainMenuGlobalSecondPart{
        grid-template-columns: auto auto auto;
    }
    .MainMenuLinkDiv{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5px;
    }

}

@media only screen and (max-width: 500px) {
    /*.MainMenuGlobalMobius7{*/
    /*    display: none;*/
    /*}*/
    .MainMenuGlobalFirstPartOut{
        display: grid;
        grid-template-columns: auto auto;
        height: auto;
    }
    .MainMenuGlobalFirstPart{
        grid-column: 2;
        grid-row: 1;
    }
    .DemoRegistration{
        left: 0;
        margin-left: auto;
        width: 100%;
    }
    .MainMenuGlobalMobius7{
        grid-column: 1/ span2;
        grid-row: 2;
    }
    /*.MainMenuGlobalSecondPart>a>.MainMenuGlobalHrefMobius,.MainMenuGlobalMobius7{*/
    /*    grid-column: 2;*/
    /*    grid-row: 1;*/

    /*}*/
    .MainMenuGlobalSecondPart{
        grid-template-columns: auto auto;
    }
    .MainMenuGlobalHrefs>a{
        margin-left: 0;
    }
    .MainMenuGlobalHrefs{
        margin-top: 15px;
        grid-column: 1/span 2;
        grid-row: 2;
        justify-content: space-between;
    }
    .MainMenuGlobalHrefMobius{
        display: flex;
        justify-content: flex-end;
    }
    .MainMenuGlobalFirstPartOut{
        justify-content: space-around;
    }
}

.EnterPersonalClientIn>.SignInform>.ButtonWhiteOut>.ButtonWhite{
    font-weight: bold;
    font-size: 18px;
    height: 58px;
    width: 128px;
}


@keyframes MainMenuGlobalLinkActive {
    from {
        width: 0;
        left: 50%;
    }

    to {
        width: 100%;
        left: 0;
    }
}


@keyframes MainMenuMovingLineFrame {
    from {
        top: var(--TopStart);
        left: var(--LeftStart);
        width: var(--widthStart);
    }
    to {
        top: var(--TopEnd);
        left: var(--LeftEnd);
        width: var(--widthEnd);
    }
}

/*.MainMenuGlobalHrefs>.MainMenuLink>.MainMenuMovingLine{*/
/*    position: absolute;*/
/*}*/

.MainMenuLink01{
    --TopStart: 1px;
    --LeftStart: 0;
    --widthStart: 100%;
    --TopEnd: 0;
    --LeftEnd: 20px;
    --widthEnd: 200px;
    animation-fill-mode: forwards;
    animation-duration: 700ms;
}

.MainMenuLink05{
    --TopStart: 1px;
    --LeftStart: 0;
    --widthStart: 100%;
    --TopEnd: 0;
    --LeftEnd: 20px;
    --widthEnd: 200px;
    animation-fill-mode: forwards;
    animation-duration: 700ms;
}

.MainMenuGlobalLinkActive{
    position: relative;
    border-bottom: unset;
    text-decoration: none;
}
.MainMenuGlobalHrefInsteadOfA{
    cursor: pointer;
}
.MainMenuGlobalHrefInsteadOfAActive{
    cursor: auto;
}

.MainMenuLink>.MobiusMenuHrefIn>.MainMenuLinkUnderline{
    height: 1px;
    width: 0;
    position: relative;

    top: 2px;
    animation-name: MainMenuGlobalLinkActive;
    animation-fill-mode: forwards;
    animation-delay: 300ms;
    animation-duration: 700ms;

}

.MainMenuLinkActive{
    text-decoration: none;
}

.MainMenuLinkActive>.MobiusMenuHrefIn>.MainMenuLinkUnderline{
    height: 3px;
    /*width: 0;*/
    top: 1px;
}

.mobiusTraderLogoBlackActive,.mobiusTraderLogoWhiteActive,.mobiusTraderLogoWhiteNo{
    display: none;
}

/*удалить что ниже */
.MainMenuGlobalMobius7Text{
    font-weight: 100;
    font-size: 21px;
    line-height: 23px;
    position: relative;
    z-index: 5;
    top: 5px;
}

.MainMenuGlobalMobius7Seven{
    font-weight: 100;
    font-size: 54px;
    position: relative;
    z-index: 5;
    margin-left: 6px;
    top: 0;
}

@media only screen and (max-width: 800px) {
    .MainMenuLink,.MainMenuLinkActive{
        /*padding: 10px 20px 1px 20px;*/
        margin: 10px 20px 1px 20px;
    }
    .TopVidgets01{
        margin-top: 30px;
    }
}


@media only screen and (max-width: 800px) {
    .MainMenuGlobal{
        height: auto;
        min-height: 180px;
    }
}