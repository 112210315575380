.APITitle{
    font-weight: 400;
    font-size: 52px;
    color: black;
    text-align: left;
    padding-bottom: 60px;
}

.APIText{
    font-weight: 400;
    font-size: 22px;
    color: black;
}

.APIText01{
    padding-bottom: 40px;
}

.APIGridTop{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-column-gap: 50px;
    border-left: 11px solid #eff4f9;
}

.APIGridTopImg{
    padding-left: 33px;
}

.APIGridTop>.APIText{
    padding-right: 40px;
}

.APIGridMain{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    background-color: #eff4f9;
    width: 100%;
    min-height: 285px;
    margin-top: 80px;
}

.APIGridMainElement{
    max-width: 229px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.APIGridMainElementImg{
    padding-bottom: 20px;
}


@media only screen and (max-width: 600px) {
    .APIGridTop{
        display: block;
        border-left: none;
    }
    .APIGridMain{
        position: relative;
    }
    .APIGridMain:before{
        content: "";
        position: absolute;
        left: -20px;
        background-color: #eff4f9;
        height: 100%;
        width: 20px;
    }
    .APIGridMain:after{
        content: "";
        position: absolute;
        right: -20px;
        background-color: #eff4f9;
        height: 100%;
        width: 20px;
    }
    .APIText{
        font-size: 18px;
    }
    .APIGridTopImg{
        padding-bottom: 30px;
    }
    .APIGridMain{
        margin-top: 30px;
    }
}

.APIGridMainElementText{
    line-height: 1.5;
}
