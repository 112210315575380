.DepositPage{

}

.ActivationDepositGrid01{
    padding-top: 20px;
}

.ActivationDepositGrid{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 30px;
}

.StandartTitleActivationH2{
    font-weight: 400;
    font-size: 26px;
    color: black;
    line-height: 27px;
    padding-bottom: 30px;
}

.ActivationDepositGridRight{
    padding: 50px 50px 44px 42px;
    background-color: #2678ff;
}

.ActivationDepositGridRightTitle{
    font-weight: 400;
    font-size: 32px;
    color: white;
    line-height: 27px;
    padding-bottom: 15px;
}
.ActivationDepositGridRightInfo{
    font-weight: 400;
    font-size: 16px;
    color: white;
    line-height: 1.5;
    height: auto;
}

.ActivationDepositGrid01>.ActivationDepositGridRight{
    max-height: 100px;
}

.ActivationDepositGrid01,.ActivationDepositGrid03{
    margin-top: 30px;
    margin-bottom: 40px;
}

.ActivationDepositGrid02{
    margin-top: 40px;
    margin-bottom: 40px;
}

.ActivationDepositGrid02>div>div>.ActivationGeneralOptionsStandartElement{
    padding-top: 20px;

}

.ActivationDepositGrid03WebmoneyIMG{
    width: 100%;
    max-width: 843px;
}

.ActivationDepositGrid03>.ActivationDepositGridRight{
    max-height: 100px;
}

.ActivationGeneralOptionsStandartElementLeft{
    margin-top: -1px;
}

@media only screen and (max-width: 600px) {
    .ActivationDepositGrid{
        display: block;

    }
    .ActivationDepositGridRightOut{
        margin-top: 40px;
    }
}

.ActivationDepositGridLeft>div>b{
    margin-right: 5px;
}