

.TerminalWebComponent3IMGCircles>div{
    cursor: pointer;

    margin-left: 9px;
    display: inline-block;
    width: 10px;
    height: 10px;

    border-radius: 50%;}

.BlockNewsChangeMarked3ImgNo{
    background: #e9eaea;
}

.TerminalWebComponent3IMGCircles{
    display: flex;
    justify-content: center;
    margin-top: 33px;
    margin-bottom: 100px;
}

.TerminalWebComponent3IMGImage{
    width: auto;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
}

.TerminalWebComponent3IMGImageIn{
    position: relative;
    z-index: -1;
}


@media only screen and (max-width: 600px) {
    .TerminalWebComponent3IMGImage{
        width: auto;
    }
}

@media only screen and (max-width: 430px) {
    .TerminalWebComponent3IMGImage,.TerminalWebComponent3IMGImage>img,.TerminalInPart02Left>img,.TerminalInPart03Right>img{
        width: 100%;
        object-fit: contain;
    }
    .TerminalWebComponent3IMGImage{
        height: auto;
        display: block;
    }
}

