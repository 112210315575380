.VacancyElement{
    border: white solid 2px;
    position: relative;
}

.VacancyElement>div{
    padding-left: 38px;
    padding-right: 38px;
}

.VacancyElementTitle{
    padding-top: 32px;
    color: #bcddff;
    font-size: 21px;
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline;
}

.VacancyElementMain{
    padding-top: 25px;
    display: grid;
    grid-template-columns: 12fr 21fr 16fr;
    padding-bottom: 35px;

}


.VacancyElementMainCity{
    padding-left: 40px;
}
.VacancyElementMainExp{
    padding-left: 40px;
}

.VacancyElementMainSalaryTitle,.VacancyElementMainCityTitle,.VacancyElementMainExpTitle{
    color: white;
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 10px;
}
.VacancyElementMainSalaryText,.VacancyElementMainCityText,.VacancyElementMainExpText{
    color: white;
    font-size: 19px;
    font-weight: 500;
}

.VacancyElementHidden{
    display:  none;
    margin-top: 20px;
}

.VacancyElementMainCityOut{
    border-left: 1px solid rgba(255,255,255,0.1);
    border-right: 1px solid rgba(255,255,255,0.1);
}

.VacancyElementHiddenContact{
    display: flex;
    background-color: white;

    color: black;
    font-size: 19px;
    font-weight: 400;
    padding: 24px 24px 24px 24px;
    position: relative;
    left: -38px;
    align-items: center;
    line-height: 27px;
}

.VacancyElementHiddenContact>img{
    padding-right: 21px;
}

.VacancyActive>.VacancyElementHidden{
    display: block;
}

.VacancyActive>.VacancyElementTitle{
    color: #31de2b;
    text-decoration: none;
}


@media only screen and (max-width: 700px) {
    .VacancyElementMain{
        display: block;
    }

    .VacancyElementMainCity{
        padding-left: 0;
    }
    .VacancyElementMainExp{
        padding-left: 0;
    }

    .VacancyElementMainCityOut{
        border-left: none;
        border-right: none;
        display: flex;
        width: auto;
    }
    .VacancyElementMainCity{
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        border-top: 1px solid rgba(255,255,255,0.1);
    }
    .VacancyElementMainExp{
        padding-top: 20px;
    }
    .VacancyElementMainSalary{
        padding-bottom: 20px;
    }

}
@media only screen and (max-width: 500px) {
    .VacancyElementTitle{
        font-size: 18px;
    }
    .VacancyElement>div{
        padding-left: 15px;
        padding-right: 15px;
    }
}
