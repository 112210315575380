.AllPagesElement>main>.PageVacanciesGrid{
    background-color: blue;
}

.PageVacanciesBackgroundElementBlueBig{
    background-color: #0035b3;
    position: fixed;
    height: 100%;
    width: 100%;
}

.AllPagesElement>main>.PageVacanciesGrid>.PageVacanciesGridAllVacancies>.VacancyElement>.VacancyElementHidden
{
    display: block;
}

.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefMobius>.MainMenuGlobalHrefMobius>.mobiusTraderLogoBlackNo{
    display: none;
}
.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefMobius>.MainMenuGlobalHrefMobius>.mobiusTraderLogoWhiteActive{
    display: block;
}

.PageVacancies{
    min-height: 900px;
    height: 100%;
    position: relative;
    padding-bottom: 30px;
}

.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>a>.MobiusHeaderLogo>.MobiusHeaderLogoText{
    color: white;
}
.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>div>a{
    color: #bcddff;
    font-size: 13px;
    font-weight: 500;
}
.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>div>a>.MainMenuLinkUnderline{
    background-color: white;
}
.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefMobius{
    color: white;
    border-bottom: 1px solid white;
}
.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalFirstPartOut>.CheckLanguageOutOut>.CheckLanguage>.CheckLanguageTitle{
    color: white;
}

.PageVacancies>header>div>.MainMenuGlobalHrefsBorder {
    background-color: white;
}

.PageVacanciesGrid{
    display: grid;
    grid-template-columns: 350px auto;
    position: relative;
    z-index: 5;
    color: white;
}

.PageVacanciesGridAllVacancies{
    position: relative;
}

.PageVacanciesGridTitle{
    color: white;
    font-size: 52px;
    text-align: left;
    width: auto;
    font-weight: 100;
    position: relative;
}

.PageVacanciesBackgroundElement01{
    position: absolute;
    left: 2%;
    z-index: 2;
}
.PageVacanciesBackgroundElement02{
    position: absolute;
    top: 143px;
    left: 20%;
    z-index: 2;
}
.PageVacanciesBackgroundElement03{
    position: absolute;
    top: 108px;
    left: 70%;
    z-index: 2;
}

.PageVacancies>main{
    max-width: 1058px;
    margin: auto;
}

.PageVacanciesGridAllVacancies>div{
    margin-bottom: 28px;
}

@keyframes forVacancies {
    from {
        opacity: 0;
        top: -50px;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

.PageVacanciesGridAllVacancies>div:first-of-type{
    animation-name: forVacancies;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-delay: 600ms;
    top: 0;
    opacity: 0;
}
.PageVacanciesGridAllVacancies>div:nth-of-type(2){
    animation-name: forVacancies;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-delay: 900ms;
    top: 0;
    opacity: 0;
}
.PageVacanciesGridAllVacancies>div:nth-of-type(3){
    animation-name: forVacancies;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-delay: 1200ms;
    top: 0;
    opacity: 0;
}

.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefMobius>.mobiusTraderLogoWhiteActive{
    display: block;
}
.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefMobius>.mobiusTraderLogoBlackNo{
    display: none;
}


@keyframes forVacanciesBackgroundElement01 {
    from {
        top: 0;
    }

    to {
        top: 472px;
    }
}

.PageVacanciesBackgroundElement01{
    animation-name: forVacanciesBackgroundElement01;
    animation-duration: 1200ms;
    animation-fill-mode: forwards;
}

.PageVacanciesBackgroundElementIMG01,.PageVacanciesBackgroundElementIMG02,.PageVacanciesBackgroundElementIMG03{
    position: relative;
}

@keyframes forVacanciesBackgroundElement02 {
    from {
        top: -329px;
    }

    to {
        top: 143px;
    }
}

.PageVacanciesBackgroundElement02{
    animation-name: forVacanciesBackgroundElement02;
    animation-duration: 600ms;
    animation-fill-mode: forwards;
}
@keyframes forVacanciesBackgroundElement03 {
    from {
        top: -364px;
    }

    to {
        top: 108px;
    }
}

.PageVacanciesGridTitle>div{
    position: relative;
}

.PageVacanciesBackgroundElement03{
    animation-name: forVacanciesBackgroundElement03;
    animation-duration: 800ms;
    animation-fill-mode: forwards;
}
@keyframes forVacanciesTitle01 {
    from {
        left: -1000px;
    }

    to {
        left: 0;
    }
}

.PageVacanciesGridTitle01{
    animation-name: forVacanciesTitle01;
    animation-duration: 300ms;
    animation-delay: 600ms;
    animation-fill-mode: forwards;
    left: -1000px;
}
@keyframes forVacanciesTitle02 {
    from {
        left: -1000px;
    }

    to {
        left: 0;
    }
}

.PageVacanciesGridTitle02{
    animation-name: forVacanciesTitle02;
    animation-duration: 300ms;
    animation-delay: 900ms;
    animation-fill-mode: forwards;
    left: -1000px;
}
@keyframes forVacanciesTitle03 {
    from {
        left: -1000px;
    }

    to {
        left: 0;
    }
}

.PageVacanciesGridTitle03{
    animation-name: forVacanciesTitle03;
    animation-duration: 300ms;
    animation-delay: 1200ms;
    animation-fill-mode: forwards;
    left: -1000px;
}
@media only screen and (max-width: 1000px) {
    .PageVacanciesGrid{
        display: block;
    }
    .PageVacanciesGridTitle{
        font-size: 30px;
        padding-bottom: 30px;
    }
}
@media only screen and (max-width: 500px) {
    .PageVacanciesBackgroundElement01{
        left: -100px;
    }.PageVacanciesBackgroundElement02{
        display: none;
    }.PageVacanciesBackgroundElement03{
                  left: 200px;
    }
}

@keyframes FonVacanciess {
    from {
        width: 400px;
        height: 400px;
        left: 400px;
        top: 400px;
    }

    to {
        width: 2400px;
        height: 2400px;
        left: -600px;
        top: -600px;
    }
}


@keyframes forGridAllVacanciesExit {
    from {
        right: 0;
    }

    50% {
        right: 40px;
    }

    to {
        right: -1500px;

    }
}

.PageVacanciesGridAllVacanciesExit{
    animation-name: forGridAllVacanciesExit;
    animation-duration: 2400ms;
    animation-fill-mode: forwards;
}

@keyframes forBackgroundElementIMGExit01 {
    from {
        top: 472px;
    }

    50% {
        top: 512px;
    }

    to {
        top: -1500px;
    }
}

.PageVacanciesBackgroundElementIMGExit1{
    animation-name: forBackgroundElementIMGExit01;
    animation-duration: 2600ms;
    animation-fill-mode: forwards;
}
@keyframes forBackgroundElementIMGExit02 {
    from {
        top: 143px;
    }

    50% {
        top: 183px;
    }

    to {
        top: -1500px;
    }
}

.PageVacanciesBackgroundElementIMGExit2{
    animation-name: forBackgroundElementIMGExit02;
    animation-duration: 2600ms;
    animation-fill-mode: forwards;
}

@keyframes forBackgroundElementIMGExit03 {
    from {
        top: 108px;
    }

    50% {
        top: 148px;
    }

    to {
        top: -1500px;
    }
}

.PageVacanciesBackgroundElementIMGExit3{
    animation-name: forBackgroundElementIMGExit03;
    animation-duration: 2600ms;
    animation-fill-mode: forwards;
}


@keyframes forPageVacanciesGridTitleExit {
    from {
        top: 0;
    }

    50% {
        top: 40px;
    }

    to {
        top: -1500px;
    }
}

.PageVacanciesGridTitleExit{
    animation-name: forPageVacanciesGridTitleExit;
    animation-duration: 2400ms;
    animation-fill-mode: forwards;
}
@keyframes forPageVacanciesCircleFonExit {
    from {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: rotate(0deg);
    }

    to {
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        transform: rotate(90deg);
    }
}

.PageVacanciesCircleFonExit{
    animation-name: forPageVacanciesCircleFonExit;
    animation-delay: 1800ms;
    animation-duration: 1200ms;
    animation-fill-mode: forwards;
}


.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLink>.MobiusMenuHrefIn>.MainMenuLinkUnderline{
    background-color: white;
}
.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLinkActive>.MobiusMenuHrefIn>.MainMenuLinkUnderline{
    background-color: white;
}

.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLink{
    color: white;
}

.PageVacancies>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLinkActive{
    color: white;
}

.PageVacanciesCircleFon{
    background-color: #0035b3;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
}

.PageVacanciesCircleFonIn{
    background-color: #0035b3;
    position: absolute;
    height: 400px;
    width: 400px;
    border-radius: 50%;
    left: 400px;
    top: 400px;
    z-index: 1;
    overflow: hidden;
    animation-name: unset;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

.PageVacanciesCircleFonInForChange{
    background-color: #0035b3;
    position: absolute;
    z-index: 20;
    height: 0;
    width: 0;
    right: 200px;
    top: 400px;
    border-radius: 50%;
}

@media only screen and (max-width: 700px) {
       .PageVacanciesCircleFon{
           min-height: 1400px;
       }
}