.MaterialsIndexFlex{
    display: flex;
    align-items: center;
}

.MaterialsIndexTitle{
    font-size: 52px;
    padding-bottom: 25px;
}

.MaterialsIndexOut{
    display: flex;
    justify-content: center;
    width: 100%;
}

.MaterialsIndex{
    width: auto;
}

.MaterialsIndexFlex{
    padding-top: 15px;
}

.MaterialsIndexFlex>img{
    margin-right: 19px;
}

@media only screen and (max-width: 1000px) {
    .MaterialsIndexOut{
        justify-content: center;
    }

    .MaterialsIndex{
        width: auto;
    }

}
@media only screen and (max-width: 600px) {
    .MaterialsIndexTitle{
        font-size: 36px;
    }
}