.PageNews>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>div>a{
    color: #1466b4;
}
.PageNews>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>div>a:hover{
    color: black;
}

/*.PageNews>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLink>.MobiusMenuHrefIn>.MainMenuLinkUnderline*/
/*.PageNews>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLink:visited{*/
/*    color: initial;*/
/*}*/

.PageNews>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLink>.MobiusMenuHrefIn>.MainMenuLinkUnderline{
    background-color: #1466b4;
}
.PageNews>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLinkActive>.MobiusMenuHrefIn>.MainMenuLinkUnderline{
    background-color: black;
}

.PageNews>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLink{
    color: #1466b4;
}

.PageNews>header>.MainMenuGlobalAll>.MainMenuGlobalSecondPart>.MainMenuGlobalHrefs>.MainMenuLinkActive{
    color: black;
}

.PageNews>header>div>.MainMenuGlobalHrefsBorder {
    background-color: #000000;
}

.PageNews>main{

    max-width: 1058px;
    margin: auto auto 40px;
}

.PageNewsGrid{
    display: grid;
    grid-template-columns: 350px auto;
    position: relative;
    z-index: 5;
    color: black;
}
.PageNewsGridTitle{
    font-size: 52px;
    text-align: left;
    width: auto;
    font-weight: 100;
}

@keyframes forNews {
    from {
        opacity: 0;
        top: -50px;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

.PageNewsGridAllNews>div{
    position: relative;
}

.PageNewsGridAllNews>.ButtonOut>.ButtonBlue{
    font-weight: 700;
}

.PageNewsGridAllNews>div:first-of-type{
    animation-name: forNews;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-delay: 200ms;
    top: 0;
    opacity: 0;
}
.PageNewsGridAllNews>div:nth-of-type(2){
    animation-name: forNews;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-delay: 400ms;
    top: 0;
    opacity: 0;
}
.PageNewsGridAllNews>div:nth-of-type(3){
    animation-name: forNews;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-delay: 600ms;
    top: 0;
    opacity: 0;
}
.PageNewsGridAllNews>div:nth-of-type(4){
    animation-name: forNews;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-delay: 800ms;
    top: 0;
    opacity: 0;
}
.PageNewsGridAllNews>div:nth-of-type(5){
    animation-name: forNews;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-delay: 1000ms;
    top: 0;
    opacity: 0;
}

@media only screen and (max-width: 700px) {
    .PageNewsGrid{
        display: block;
    }
    .PageNewsGridTitle{
        font-size: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
    }
    .PageNewsGridTitle01{
        padding-right: 10px;
    }
}