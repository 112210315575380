.SiteDNSPage>.PageMainPartBig{
    max-width: 877px;
}

.ActivationSite01{
    padding-top: 30px;

}

.ActivationSiteSmallTitle{
    font-weight: 500;
    font-size: 18px;
    color: black;
    line-height: normal;
    padding-bottom: 12px;
}

.ActivationSite02{
    padding-top: 30px;
    padding-bottom: 20px;
}

.ActivationSite03{
    padding-top: 30px;
    padding-bottom: 30px;
}

.ActivationSite04{
    padding-top: 30px;
}

.ActivationGeneralOptionsStandartElementRightBBB{
    display: flex;
    justify-content: left;
}


.ActivationGeneralOptionsStandartElementRightBBB>.ActivationGeneralOptionsStandartElementRightTitle{
    font-weight: 400;
    padding-right: 5px;
}


.ActivationGeneralOptionsStandartElementRightBBB>.ActivationGeneralOptionsStandartElementRightText{
    font-weight: 700;

}

.ActivationSite05{
    padding-top: 30px;
    padding-bottom: 30px;
}

.ActivationSiteSmallTitleItalic{
    font-style: italic;
}

.ActivationSiteAttention>div{
    font-weight: 700;
    padding-right: 5px;
}

.ActivationSiteAttention{
    margin-top: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.ActivationSiteAttention>img{
    padding-right: 16px;
}


.ActivationSite06{
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0;
}