.MainMenuLinkUnderline{
    background-color: #0079bf;
}

.MainMenuLink:hover>.MainMenuLinkUnderline{
    background-color: green;
}

.MainMenuGlobalSecondPart>.MainMenuGlobalHrefMobius:visited,.MainMenuGlobalSecondPart>.MainMenuGlobalHrefMobius{
    color: black;
}

.MainMenuLinkUnderline{
    height: 1px;
}

.IndexWidgetButtonOut01{
    margin-top: 25px;
}

.PageMainPartMainSVG{
    width: 100%;
    color: white;
    margin-top: 80px;
    position: relative;
}
.PageMainPartMainSVGImage{
    position: relative;
    background-color : #1da4f2;
}

.PageMainPartMainSVGImage>img{
    position: absolute;
    top: -1px;
}

.PageMainPartMainSVG>.PageMainPartMainSVGElement {
    min-height: 200px;
    display: grid;
    grid-template-columns: 40px auto;
    grid-column-gap: 50px;
    padding: 35px 40px;
}

.PageMainPartMainSVGElement01{
    background-color: #1da4f2;
    display: flex;
    align-items: center;
}
.PageMainPartMainSVGElement02{
    background-color: #31b0fa;
    display: flex;
    align-items: center;
}
.PageMainPartMainSVGElement03{
    background-color: rgba(60, 180, 250, 0.72);
    display: flex;
    align-items: center;
}

.PageMainPartMainSVGElementNumber{
    font-size: 70px;
    color: #bcdcff;
    position: relative;
    top: 20px;
}


.PageMainPartMainSVGElementNumber{
    display: flex;
    align-items: center;
    height: 60px;
    position: relative;
    top: 1px;
}

.PageMainPartMainSVGElementText{
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 500px) {

    .PageMainPartMainSVG>div{
        display: block;
    }
    .PageMainPartMainSVGElementNumber{
        text-align: center;
        padding-bottom: 30px;
    }
    .PageMainPartMainSVGElementText{
        font-size: 17px;
        line-height: 17px;
    }
    .StandartTitleWidget{
        font-size: 30px;
    }
    .StandartTextWidget{
        font-size:  18px;
        line-height: 17px;
    }
}

.WidgetCSSRight{
    padding-right: 10px;
}