.PersonalPageInput{
    display: grid;
    position: relative;
    width: 400px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.PersonalPageInput>label{
    position: relative;
}

.PersonalPageInput>label>input:hover {
    background-color: #eeeeee;
}

.PersonalPageInput>label>input:focus{
    outline: 0;
    outline-offset: 0;
    background: none;
}

.PersonalPageInput>label>input:focus + span {
    transform: translateY(-14px) scale(.75);
    opacity: 1;
}

.PersonalPageInput>label>input:not(:placeholder-shown) + span {
    transform: translateY(-14px) scale(.75);
    color: #f5b95b;
    opacity: 1;
}

.PersonalPageInput>label>.TextPlaceHolder {
    position: absolute;
    top: -2px;
    left: 0;
    font-size: 24px;
    color: #190a0d;
    opacity: 0.5;
    font-weight: 300;
    transform-origin: 0 0;
    transition: all .2s ease;
}


.PersonalPageInput>label>input {
    width: 100%;
    border: 0;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid rgba(25, 10, 13, 0.3);
    background: none;
    transition: all .15s ease;
}

.PersonalPageInputBottom{
    position: relative;
    top: 4px;
    font-size: 16px;
    color: #575757;
}

.PersonalPageInputBottomLang{
    display: flex;
}

.PersonalPageInputBottomAllLang{
    width: 92px;
    height: 123px;
    background-color: white;
    box-shadow: 0 4px 10px 0 rgba(0, 51, 100, 0.25);
    border-radius: 3px;
    position: absolute;
    z-index: 50;
    padding: 14px 14px 10px 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: -15px;
    left: -15px;
}

.PersonalPageInputBottomAllLangOut{
    position: relative;
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
    top: -1px;
    margin-left: 10px;
}

.PersonalPageInputBottomAllLangOut>.CheckLanguageNo{
    display: none;
}

.BigWidgetGray{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(0,0,0,0.2);
}

.WidgetEveryPageTextSmall{
    font-size: 15px;
    line-height: 18px;
}